import {
  FETCH_PROVINCE_LIST_COMMENCED,
  FETCH_PROVINCE_LIST_SUCCESS,
  FETCH_PROVINCE_LIST_FAILURE,
  FETCH_REGENCY_LIST_COMMENCED,
  FETCH_REGENCY_LIST_SUCCESS,
  FETCH_REGENCY_LIST_FAILURE,
  FETCH_DISTRICT_LIST_COMMENCED,
  FETCH_DISTRICT_LIST_SUCCESS,
  FETCH_DISTRICT_LIST_FAILURE,
  FETCH_VILLAGE_LIST_COMMENCED,
  FETCH_VILLAGE_LIST_SUCCESS,
  FETCH_VILLAGE_LIST_FAILURE,
  CALCULATE_DELIVERY_COST_COMMENCED,
  CALCULATE_DELIVERY_COST_SUCCESS,
  CALCULATE_DELIVERY_COST_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  loadingLocalities: false,
  provinces: [],
  regencies: [],
  districts: [],
  villages: [],
  deliveryOptions: [],
  loadingDelivery: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        provinces: action.payload,
        error: "",
        loadingLocalities: false,
      };
    case FETCH_PROVINCE_LIST_FAILURE:
      return {
        ...state,
        error: "Could not connect to the server. Please check your connection.",
        loadingLocalities: false,
      };
    case FETCH_PROVINCE_LIST_COMMENCED:
      return {
        ...state,
        error: "",
        loadingLocalities: true,
      };
    case FETCH_REGENCY_LIST_SUCCESS:
      return {
        ...state,
        regencies: action.payload,
        error: "",
        loadingLocalities: false,
      };
    case FETCH_REGENCY_LIST_FAILURE:
      return {
        ...state,
        error: "Could not connect to the server. Please check your connection.",
        loadingLocalities: false,
      };
    case FETCH_REGENCY_LIST_COMMENCED:
      return {
        ...state,
        error: "",
        loadingLocalities: true,
      };
    case FETCH_DISTRICT_LIST_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        error: "",
        loadingLocalities: false,
      };
    case FETCH_DISTRICT_LIST_FAILURE:
      return {
        ...state,
        error: "Could not connect to the server. Please check your connection.",
        loadingLocalities: false,
      };
    case FETCH_DISTRICT_LIST_COMMENCED:
      return {
        ...state,
        error: "",
        loadingLocalities: true,
      };
    case FETCH_VILLAGE_LIST_SUCCESS:
      return {
        ...state,
        villages: action.payload,
        error: "",
        loadingLocalities: false,
      };
    case FETCH_VILLAGE_LIST_FAILURE:
      return {
        ...state,
        error: "Could not connect to the server. Please check your connection.",
        loadingLocalities: false,
      };
    case FETCH_VILLAGE_LIST_COMMENCED:
      return {
        ...state,
        error: "",
        loadingLocalities: true,
      };
    case CALCULATE_DELIVERY_COST_SUCCESS:
      return {
        ...state,
        loadingDelivery: false,
        deliveryOptions: action.payload,
      };
    case CALCULATE_DELIVERY_COST_FAILURE:
      return {
        ...state,
        errorDelivery:
          "Could not fetch any courier service. Please check your connection.",
        loadingDelivery: false,
        deliveryOptions: [],
      };
    case CALCULATE_DELIVERY_COST_COMMENCED:
      return {
        ...state,
        errorDelivery: "",
        loadingDelivery: true,
        deliveryOptions: [],
      };

    default:
      return state;
  }
};
