import {
  FETCH_CHALLENGE_GROUP_COMMENCED,
  FETCH_CHALLENGE_GROUP_SUCCESS,
  FETCH_CHALLENGE_GROUP_FAILURE,
  FETCH_DONATION_ADDONS_COMMENCED,
  FETCH_DONATION_ADDONS_SUCCESS,
  FETCH_DONATION_ADDONS_FAILURE,
  FETCH_GROUP_MEMBERS_COMMENCED,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_MEMBERS_FAILURE,
  FETCH_GROUP_DONATIONS_COMMENCED,
  FETCH_GROUP_DONATIONS_SUCCESS,
  FETCH_GROUP_DONATIONS_FAILURE,
  SET_PAYMENT_INFORMATION,
  FETCH_CHALLENGE_PARTNER_SUCCESS,
  FETCH_CHALLENGE_PARTNER_FAILURE,
  FETCH_CHALLENGE_PARTNER_COMMENCED,
  ENROL_CHALLENGE_SUCCESS,
  ENROL_CHALLENGE_FAILURE,
  ENROL_CHALLENGE_COMMENCED,
  RESET_REGISTRANT_INFO,
} from "../actions/types";

const INITIAL_STATE = {
  groupInfo: [],
  loadingGroupInfo: false,
  graphData: [],
  loadingGraphData: false,
  groupMembers: [],
  loadingGroupMembers: false,
  groupDonations: [],
  loadingGroupDonations: false,
  addOns: [],
  loadingAddOns: false,
  challengePartner: [],
  loadingChallenge: false,
  mileageTotal: [],
  loadingMileageTotal: false,
  newRegistrant: null,
  loadingRegistration: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHALLENGE_GROUP_SUCCESS:
      return {
        ...state,
        groupInfo: action.payload,
        loadingGroupInfo: false,
      };
    case FETCH_CHALLENGE_GROUP_FAILURE:
      return {
        ...state,
        groupInfo: [],
        loadingGroupInfo: false,
      };
    case FETCH_CHALLENGE_GROUP_COMMENCED:
      return {
        ...state,
        groupInfo: [],
        loadingGroupInfo: true,
      };

    case FETCH_DONATION_ADDONS_COMMENCED:
      return {
        ...state,
        addOns: [],
        loadingAddOns: true,
      };
    case FETCH_DONATION_ADDONS_SUCCESS:
      return {
        ...state,
        addOns: action.payload,
        loadingAddOns: false,
      };
    case FETCH_DONATION_ADDONS_FAILURE:
      return {
        ...state,
        addOns: [],
        loadingAddOns: false,
      };

    case FETCH_GROUP_MEMBERS_COMMENCED:
      return {
        ...state,
        groupMembers: [],
        loadingGroupMembers: true,
      };
    case FETCH_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        groupMembers: action.payload,
        loadingGroupMembers: false,
      };
    case FETCH_GROUP_MEMBERS_FAILURE:
      return {
        ...state,
        groupMembers: [],
        loadingGroupMembers: false,
      };
    case FETCH_GROUP_DONATIONS_COMMENCED:
      return {
        ...state,
        groupDonations: [],
        loadingGroupDonations: true,
      };
    case FETCH_GROUP_DONATIONS_SUCCESS:
      return {
        ...state,
        groupDonations: action.payload,
        loadingGroupDonations: false,
      };
    case FETCH_GROUP_DONATIONS_FAILURE:
      return {
        ...state,
        groupDonations: [],
        loadingGroupDonations: false,
      };
    case FETCH_CHALLENGE_PARTNER_COMMENCED:
      return {
        ...state,
        challengePartner: null,
        error: "",
        loadingChallenge: true,
      };
    case FETCH_CHALLENGE_PARTNER_SUCCESS:
      return {
        ...state,
        challengePartner: action.payload,
        error: "",
        loadingChallenge: false,
      };
    case FETCH_CHALLENGE_PARTNER_FAILURE:
      return {
        ...state,
        challengePartner: null,
        error:
          "Could not find the partner for respective challenge. Please check your connection.",
        loadingChallenge: false,
      };
    case ENROL_CHALLENGE_SUCCESS:
      return {
        ...state,
        newRegistrant: action.payload,
        error: "",
        loadingRegistration: false,
      };
    case ENROL_CHALLENGE_FAILURE:
      return {
        ...state,
        newRegistrant: null,
        error: "",
        loadingRegistration: false,
      };
    case ENROL_CHALLENGE_COMMENCED:
      return {
        ...state,
        newRegistrant: null,
        error: "",
        loadingRegistration: true,
      };
    case RESET_REGISTRANT_INFO:
      return {
        ...state,
        newRegistrant: null,
        error: "",
        loadingRegistration: false,
      };
    default:
      return state;
  }
};
