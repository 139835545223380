import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";

// the hoc
import { withNamespaces } from "react-i18next";

import Home from "./pages/Home";
import About from "./pages/About";
import Event from "./pages/Event";
import Register from "./pages/Register";
import PageNotFound from "./pages/PageNotFound";
import Acknowledgement from "./pages/Acknowledgement";
import Donation from "./pages/Donation";
import Performers from "./pages/Performers";
import Chart from "./pages/Chart";
import Guide from "./pages/Guide";
import FAQ from "./pages/FAQ";
import Leaderboard from "./pages/Leaderboard";

function App({ t }) {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 750,
      easing: "ease-out-quart",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home t={t} />
        </Route>
        <Route path="/runners">
          <Performers t={t} />
        </Route>
        <Route path="/programme">
          <Event t={t} />
        </Route>
        <Route path="/leaderboard">
          <Leaderboard t={t} />
        </Route>
        <Route path="/guide">
          <Guide t={t} />
        </Route>
        <Route path="/about">
          <About t={t} />
        </Route>
        <Route path="/faq">
          <FAQ t={t} />
        </Route>
        <Route path="/about">
          <About t={t} />
        </Route>
        {/* <Route path="/register">
          <Register t={t} />
        </Route> */}
        <Route path="/donate">
          <Donation t={t} />
        </Route>
        <Route path="/chart">
          <Chart t={t} />
        </Route>
        <Route path="/confirm">
          <Acknowledgement t={t} />
        </Route>
        <Route path="*">
          <PageNotFound t={t} />
        </Route>
      </Switch>
    </>
  );
}

export default withNamespaces()(App);
