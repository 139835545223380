import React from "react";

import Header from "../partials/Header";
import HeroEvent from "../partials/HeroEvent";
import Detail from "../partials/Detail";
import HeroBeneficiaries from "../partials/HeroBeneficiaries";
import Beneficiary from "../partials/Beneficiary";
import Footer from "../partials/Footer";

function Event({ t }) {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header t={t} />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroEvent t={t} />
        <Detail t={t} />
        <HeroBeneficiaries t={t} />
        <Beneficiary t={t} />
        {/* <Mechanism /> */}
        {/* <Timeline />
        <Career />
        <FeaturesAnimation /> */}
        {/* <CtaContact /> */}
      </main>

      {/*  Site footer */}
      <Footer t={t} />
    </div>
  );
}

export default Event;
