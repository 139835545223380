import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";

import Header from "../partials/Header";
import Footer from "../partials/Footer";

// actions
import { resetRegistrantInfo } from "../actions";

function Acknowledgement({ t }) {
  // constants declaration
  const userGuideUrl = `https://aktivin.id/user-guide${
    i18n && i18n.language === "en" ? "-en" : ""
  }/#team`;

  // history instantiation
  let history = useHistory();

  // variables instantiation
  const [registrationInfo, setRegistrationInfo] = useState(null);

  // Hooks instantiation
  const dispatch = useDispatch();

  // Props from reducers
  const newRegistrant = useSelector((state) => state.challenge.newRegistrant);

  // component did mount
  
  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:overflow-visible">
      {/*  Site header */}
      <Header t={t} />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="mt-32 pb-12 md:pt-8 md:pb-20 flex flex-col flex-grow items-center">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1 mb-4">{t("Thank you")}!</h1>
                <p className="text-xl text-gray-600 dark:text-gray-400">
                  {t("We have received your donation.")}
                </p>
              </div>

              {/* Leaderboard list */}
              <div className="text-center mb-12 md:mb-16">
                {t("Please find below your registration code")}.
                <div className="text-xl font-semibold my-3">
                  {registrationInfo && registrationInfo.promoCode}
                </div>
              </div>

              <div className="text-lg text-center mb-12 md:mb-16">
                {t("Acknowledgement Text")}
                <div className="text-md my-3">
                  {t("Acknowledgement Disclaimer")}
                </div>
              </div>

              <button
                onClick={() => window.open(userGuideUrl, "_blank")}
                className={`btn text-white bg-theme-button hover:bg-theme-buttonHover flex items-center w-auto`}
              >
                <div className="flex items-center w-full md:w-auto">
                  <span className="mr-2">{t("Aktivin User Guide")}</span>
                </div>
              </button>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer t={t} />
    </div>
  );
}

export default Acknowledgement;
