import React, { useState, useEffect } from "react";
import { Doughnut, Line, Bar, HorizontalBar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// components
import Modal from "../utils/Modal";
import i18n from "../i18n";

import { generateRandomColor } from "../helpers";
import { fetchDonationGraph, fetchChallengeGroupInformation } from "../actions";

function DonationChart({ t }) {
  const donationPerPts = 50000;

  // State instantiation
  const [chartType, setChartType] = useState("team");
  const [teamLabels, setTeamLabels] = useState([]);
  const [cumulativeLabels, setCumulativeLabels] = useState([]);
  const [teamDataDonation, setTeamDataDonation] = useState([]);
  const [teamDataMileage, setTeamDataMileage] = useState([]);
  const [cumulativeDataDonation, setCumulativeDataDonation] = useState([]);
  const [cumulativeDataMileage, setCumulativeDataMileage] = useState([]);
  const [barHeight, setBarHeight] = useState(600);

  // Hooks instantiation
  const dispatch = useDispatch();

  // Props from reducers
  const teams = useSelector((state) => state.challenge.groupInfo);
  const teamsGraphLoading = useSelector(
    (state) => state.challenge.loadingGroupInfo
  );
  const donation = useSelector((state) => state.donation.graphData);
  const donationGraphLoading = useSelector(
    (state) => state.donation.loadingGraphData
  );
  const donationProgramId = useSelector((state) => state.config.donationId);

  useEffect(() => {
    dispatch(fetchDonationGraph(donationProgramId));
    dispatch(fetchChallengeGroupInformation(donationProgramId));
  }, []);

  useEffect(() => {
    let newTeamLabels = [];
    let newTeamDataDonation = [];
    let newTeamDataMileage = [];
    let newBarHeight = teams.length * 20 < 600 ? 600 : teams.length * 18;

    if (teams && !teamsGraphLoading) {
      teams
        .sort(function (a, b) {
          return parseInt(b.packageDonated) > parseInt(a.packageDonated)
            ? 1
            : parseInt(b.packageDonated) == parseInt(a.packageDonated)
              ? parseInt(b.pointBurned) > parseInt(a.pointBurned)
                ? 1
                : -1
              : -1;
        })
        .map((team) => {
          newTeamLabels.push(team.name);
          // newTeamLabels.push("Team Name");
          newTeamDataDonation.push(team.packageDonated);
          newTeamDataMileage.push(team.pointBurned);
        });
    }

    setTeamLabels(newTeamLabels);
    setTeamDataDonation(newTeamDataDonation);
    setTeamDataMileage(newTeamDataMileage);
    setBarHeight(newBarHeight);
  }, [teams, teamsGraphLoading]);

  useEffect(() => {
    let newCumulativeDataDonation = [];
    let newCumulativeDataMileage = [];
    let newCumulativeLabels = [];

    if (donation && !donationGraphLoading) {
      donation.map((donate) => {
        if (newCumulativeDataDonation.length < 1) {
          newCumulativeDataDonation.push(donate.total);
          newCumulativeDataMileage.push(donate.pointBurned);
        } else {
          newCumulativeDataDonation.push(
            newCumulativeDataDonation[newCumulativeDataDonation.length - 1] +
            donate.total
          );
          newCumulativeDataMileage.push(
            newCumulativeDataMileage[newCumulativeDataMileage.length - 1] +
            donate.pointBurned
          );
        }

        newCumulativeLabels.push(moment(donate.paid_date).format("DD/MM"));
      });

      setCumulativeDataDonation(newCumulativeDataDonation);
      setCumulativeDataMileage(newCumulativeDataMileage);
      setCumulativeLabels(newCumulativeLabels);
    }
  }, [donation, donationGraphLoading]);

  const data1 = {
    labels: cumulativeLabels,
    datasets: [
      {
        label: t("Cumulative Donation"),
        backgroundColor: "rgba(250, 180, 19, 0.2)",
        borderColor: "rgba(250, 180, 19, 1)",
        //borderWidth: 1,
        hoverBackgroundColor: "rgba(250, 180, 19, 0.4)",
        hoverBorderColor: "rgba(250, 180, 19, 1)",
        data: cumulativeDataDonation,
      },
      {
        label: t("Cumulative Mileage"),
        backgroundColor: "rgba(207, 76, 22, 0.2)",
        borderColor: "rgba(207, 76, 22, 1)",
        //borderWidth: 1,
        hoverBackgroundColor: "rgba(207, 76, 22, 0.4)",
        hoverBorderColor: "rgba(207, 76, 22, 1)",
        data: cumulativeDataMileage,
      },
    ],
  };

  const data2 = {
    labels: teamLabels,
    datasets: [
      {
        label: t("Team Donation"),
        data: teamDataDonation,
        backgroundColor: "rgba(250, 180, 19, 0.6)",
        hoverBackgroundColor: "rgba(250, 180, 19, 0.4)",
        barThickness: 6,
      },
      {
        label: t("Team Mileage"),
        data: teamDataMileage,
        backgroundColor: "rgba(207, 76, 22, 0.6)",
        hoverBackgroundColor: "rgba(207, 76, 22, 0.4)",
        barThickness: 4,
      },
    ],
  };

  var options1 = {
    maintainAspectRatio: false,
    tooltips: {
      mode: "label",
      label: "mylabel",
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            data.datasets[tooltipItem.datasetIndex].label +
            ": " +
            Number(tooltipItem.value).toLocaleString(i18n.language)
          );
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback(value) {
              return Number(value).toLocaleString(i18n.language);
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  var options2 = {
    maintainAspectRatio: false,
    tooltips: {
      mode: "label",
      label: "mylabel",
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.value).toLocaleString(i18n.language);
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback(value) {
              return Number(value).toLocaleString(i18n.language);
            },
          },
        },
      ],
    },
  };

  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-col">
        <div className="mt-20 pb-12 md:pt-8 md:pb-20 flex flex-col items-center w-full">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20" data-aos="fade-down"
            data-aos-delay="100">
            <h1 className="mb-4 pt-12 text-3xl lg:text-6xl font-heading">{t("Donation Chart")}</h1>
            {/* <p className="text-xl text-gray-600 dark:text-gray-400">
              {t("Pick the bands that you want to support")}
            </p> */}
          </div>

          {/* Button to control which chart to be shown */}
          <div className="mb-10 flex w-full justify-center" data-aos="fade-up"
                data-aos-delay="150">
            <a
              href="#"
              onClick={() => setChartType("team")}
              className={`mt-6 flex items-center justify-center border dark:border-gray-700 ${chartType === "team"
                  ? "bg-theme-button hover:bg-theme-buttonHover z-100 text-white "
                  : ""
                } px-4 py-2 font-bold w-full md:w-1/4`}
            >
              <span>{t("Team")}</span>
            </a>
            <a
              href="#"
              onClick={() => setChartType("cumulative")}
              className={`mt-6 flex items-center justify-center border dark:border-gray-700 ${chartType === "cumulative"
                  ? "bg-theme-button hover:bg-theme-buttonHover z-100 text-white "
                  : ""
                } px-4 py-2 font-bold w-full md:w-1/4`}
            >
              <span>{t("Cumulative")}</span>
            </a>
          </div>

          {/* Chart */}
          <div className={`w-full`} data-aos="fade-up"
                data-aos-delay="200">
            {chartType === "team" && teamDataDonation.length > 0 ? (
              <div className="relative h-full flex-grow w-full">
                <HorizontalBar
                  data={data2}
                  options={options1}
                  height={barHeight}
                  width="100%"
                />
              </div>
            ) : chartType === "cumulative" ? (
              <Line
                data={data1}
                options={options2}
                height={"400"}
                width="100%"
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default DonationChart;
