import { combineReducers } from "redux";
import config from "./config";
import ChallengeReducer from "./ChallengeReducer";
import DonationReducer from "./DonationReducer";
import LogisticReducer from "./LogisticReducer";
import LeaderboardReducer from "./LeaderboardReducer";

export default combineReducers({
  config,
  challenge: ChallengeReducer,
  donation: DonationReducer,
  logistic: LogisticReducer,
  leaderboard: LeaderboardReducer,
});
