const INITIAL_STATE = {
  challengeId: 72,
  donationId: 10,
  programName: "PWC 50th Anniversary",
  campaignId: "5Z4ezkhhWObGbvRwfrbtCEJGAHwh4RLXc06f0Pt6PJVxDxMSz19gL2PG8UqIQaNi950BkVBzgPNyO76ugrTY8fCRK2dAflKAsRl0vU0w9gVnvP5inZSrZnKDMKwY6IrmMxrs",
  homepageUrl: "https://pwc50.aktivin.id/"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
