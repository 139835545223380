import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import Header from "../partials/Header";
import Footer from "../partials/Footer";

// actions
import { fetchLeaderboardList } from "../actions";

function Leaderboard({ t }) {
  // constants declaration

  // variables instantiation
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Hooks instantiation
  const dispatch = useDispatch();

  // Props from reducers
  const challengeId = useSelector((state) => state.config.challengeId)
  const leaderboardList = useSelector(
    (state) => state.leaderboard.leaderboardList
  );

  // component did mount
  useEffect(() => {
    // code to run on component mount
    dispatch(fetchLeaderboardList(challengeId));
  }, []);

  // scrolling to top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:overflow-visible">
      {/*  Site header */}
      <Header t={t} />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="mt-32 pb-12 md:pt-8 md:pb-20 flex flex-col flex-grow">
              {/* Section header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20" data-aos="fade-down"
                data-aos-delay="100">
                <h1 className="mb-4 text-3xl lg:text-6xl font-heading">Leaderboard</h1>
                {/* <p className="text-xl text-gray-600 dark:text-gray-400">
              Find your photos here and tap on the images that you want to
              order.
            </p> */}
              </div>

              {/* Leaderboard list */}
              <table className="w-full border-collapse relative" data-aos="fade-up"
                data-aos-delay="150">
                <thead className="sticky top-20">
                  <tr className="bg-white dark:bg-gray-900">
                    <th
                      className={
                        "px-6 align-middle text-center border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-theme-button dark:border-gray-800 border-gray-300 text-white"
                      }
                    >
                      Name
                    </th>
                    <th
                      className={
                        "px-6 align-middle text-center border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-theme-button dark:border-gray-800 border-gray-300 text-white"
                      }
                    >
                      Qualifying Point
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leaderboardList &&
                    leaderboardList.map((item, index) => {
                      return (
                        <tr className="text-sm" key={item.login_id.toString()}>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-no-wrap p-4 text-left flex items-center">
                            <span
                              className={
                                "flex text-gray-700 dark:text-white items-center font-semibold"
                              }
                            >
                              <div>
                                <img
                                  className="mr-3"
                                  src={item.profile_img_url}
                                  width="60"
                                />
                              </div>
                              <div className="hidden md:block">
                                {item.fullname}
                              </div>
                              <div className="md:hidden">{item.name}</div>
                            </span>
                          </th>
                          <td className="text-center text-gray-700 dark:text-white font-bold">
                            {item.qualifying_point}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div className="mt-5 bg-white dark:bg-gray-900">
                <div className="text-left text-sm">
                  Leaderboard as per {new Date().toString()}
                </div>
              </div>

              {/* Section footer */}
              <div className="max-w-3xl mx-auto fixed bottom-10 right-10 z-10">
                <div className="flex flex-col justify-end">
                  <button
                    onClick={scrollToTop}
                    //disabled={isProcessDisabled}
                    className={`text-white bg-theme-button hover:bg-theme-buttonHover px-3 py-2 rounded-full`}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer t={t} />
    </div>
  );
}

export default Leaderboard;
