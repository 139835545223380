import React from "react";

function HeroAbout({ t }) {
  return (
    <section className="relative">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-8 md:pb-12" data-aos="fade-down" data-aos-delay="100">
            <h2 className="mb-4 text-3xl lg:text-6xl font-heading">
              {t("Programme Details")}
            </h2>
          </div>
          </div>
      </div>
    </section>
  );
}

export default HeroAbout;
