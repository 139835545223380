import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Dropdown from "../utils/Dropdown";
import Transition from "../utils/Transition.js";

import i18n from "../i18n";
import moment from "moment";

function Header({ t }) {
  let location = useLocation();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNav = useRef(null);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const timestamp = moment().unix();
  const timestampDonationOpen = 1616173200;

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // Handle light modes
  const [darkMode, setDarkMode] = useState(() => {
    const dark = localStorage.getItem("dark-mode");
    if (dark === null) {
      return false;
    } else {
      return dark === "true";
    }
  });

  useEffect(() => {
    localStorage.setItem("dark-mode", darkMode);
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <header className="fixed top-0 w-screen z-30 bg-white dark:bg-gray-900 font-heading">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-5">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Aktivin">
              <img
                src={require("../logo/pwc.png")}
                width="90"
                height="60"
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow flex-wrap items-center font-medium text-theme-button-light dark:text-theme-button-dark hover:text-theme-hoverButton-light dark:hover:text-theme-hoverButton-dark">
            {/* <li>
                <Link
                  to="/event"
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out ${
                    location.pathname === "/event" ? "font-extrabold" : ""
                  }`}
                >
                  {t("Event Mechanism")}
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/register"
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out text-theme-buttonSupp ${
                    location.pathname === "/register" ? "font-extrabold" : ""
                  }`}
                >
                  {t("Register Your Band")}
                </Link>
              </li> */}
              <li>
                <Link
                  to={timestamp >= timestampDonationOpen ? "/donate" : "/runners"}
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out ${
                    location.pathname === "/donate" || location.pathname === "/runners" ? "font-extrabold" : ""
                  }`}
                >
                  {t(timestamp >= timestampDonationOpen ? "Donate" : "Teams")}
                </Link>
              </li>
              <li>
                <Link
                  to="/chart"
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out ${
                    location.pathname === "/chart" ? "font-extrabold" : ""
                  }`}
                >
                  {t("Chart")}
                </Link>
              </li>
              <li>
                <Link
                  to={"/leaderboard"}
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out ${
                    location.pathname === "/leaderboard" ? "font-extrabold" : "" }`}
                >
                  {t("Leaderboard")}
                </Link>
              </li>
              <li>
                <a
                  href="https://wa.me/628992988123"
                  target="_blank"
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out`}
                >
                  {t("Contact Us")}
                </a>
              </li>
              {/* <li>
                <Link
                  to="/about"
                  className={`px-5 py-2 flex items-center transition duration-150 ease-in-out ${
                    location.pathname === "/about" ? "font-extrabold" : ""
                  }`}
                >
                  {t("About")}
                </Link>
              </li> */}
              {/*<Dropdown title="Resources">
                <li>
                  <Link
                    to="/help"
                    className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                  >
                    Help center
                  </Link>
                </li>
                <li>
                  <Link
                    to="/404"
                    className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                  >
                    404
                  </Link>
                </li>
              </Dropdown>*/}
            </ul>

            {/* Desktop lights switch */}
            {/* <div className="form-switch focus-within:outline-blue flex flex-col justify-center mx-3">
              <input
                type="checkbox"
                name="light-switch"
                id="light-switch-desktop"
                className="light-switch sr-only"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
              />
              <label className="relative" htmlFor="light-switch-desktop">
                <span
                  className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10"
                  aria-hidden="true"
                ></span>
                <svg
                  className="absolute inset-0"
                  width="44"
                  height="24"
                  viewBox="0 0 44 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    className="fill-current text-white"
                    fillRule="nonzero"
                    opacity=".88"
                  >
                    <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />
                    <circle cx="32" cy="12" r="3" />
                    <circle fillOpacity=".4" cx="12" cy="12" r="6" />
                    <circle fillOpacity=".88" cx="12" cy="12" r="3" />
                  </g>
                </svg>
                <span className="sr-only">Switch to light / dark version</span>
              </label>
            </div> */}

            {/* Desktop CTA on the right */}
            <ul className="flex justify-end flex-wrap items-center">
              {/*<li>
                <Link to="/contact" className="btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-6">Request code</Link>
              </li>*/}
              <button className="mx-2 shadow" onClick={() => changeLanguage("id")}>
                <img src={require("../images/a_ID.jpg")} width="30" />
              </button>
              <button className="mx-2 shadow" onClick={() => changeLanguage("en")}>
                <img src={require("../images/a_UK.png")} width="36" />
              </button>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">
            {/* Mobile lights switch */}
            {/* <div className="form-switch focus-within:outline-blue flex flex-col justify-center mr-2 -mt-0.5">
              <input
                type="checkbox"
                name="light-switch"
                id="light-switch-mobile"
                className="light-switch sr-only"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
              />
              <label className="relative" htmlFor="light-switch-mobile">
                <span
                  className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10"
                  aria-hidden="true"
                ></span>
                <svg
                  className="absolute inset-0"
                  width="44"
                  height="24"
                  viewBox="0 0 44 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    className="fill-current text-white"
                    fillRule="nonzero"
                    opacity=".88"
                  >
                    <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />
                    <circle cx="32" cy="12" r="3" />
                    <circle fillOpacity=".4" cx="12" cy="12" r="6" />
                    <circle fillOpacity=".88" cx="12" cy="12" r="3" />
                  </g>
                </svg>
                <span className="sr-only">Switch to light / dark version</span>
              </label>
            </div> */}

            <ul className="flex justify-end flex-wrap items-center mr-2">
              {/*<li>
                <Link to="/contact" className="btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-6">Request code</Link>
              </li>*/}
              <button className="shadow mx-2" onClick={() => changeLanguage("id")}>
                <img src={require("../images/a_ID.jpg")} width="30" />
              </button>
              <button className="shadow mx-2" onClick={() => changeLanguage("en")}>
                <img src={require("../images/a_UK.png")} width="36" />
              </button>
            </ul>

            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              >
                <div className="py-6 pr-4 pl-20">
                  {/* Links */}
                  <ul>
                    {/* <li>
                      <Link
                        to="/about"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        About
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/event"
                        className={`flex text-theme-button hover:text-theme-buttonHover py-2 ${
                          location.pathname === "/event"
                            ? "font-extrabold"
                            : ""
                        }`}
                      >
                        {t("Event Mechanism")}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/register"
                        className={`flex text-theme-button hover:text-theme-buttonHover py-2 ${
                          location.pathname === "/register"
                            ? "font-extrabold"
                            : ""
                        }`}
                      >
                        {t("Register Your Band")}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/gallery"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/leaderboard"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Leaderboard
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/donate"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Donate
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/chart"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Chart
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to={timestamp >= timestampDonationOpen ? "/donate" : "/runners"}
                        className={`flex py-2 ${
                          location.pathname === "/donate" || location.pathname === "/runners"
                            ? "font-extrabold"
                            : ""
                        }`}
                      >
                        {t(timestamp >= timestampDonationOpen ? "Donate" : "Teams")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/chart"
                        className={`flex py-2 ${
                          location.pathname === "/chart"
                            ? "font-extrabold"
                            : ""
                        }`}
                      >
                        {t("Chart")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/leaderboard"
                        className={`flex py-2 ${
                          location.pathname === "/leaderboard" ? "font-extrabold" : ""
                        }`}
                      >
                        {t("Leaderboard")}
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/628992988123"
                        className={`flex py-2`}
                      >
                        {t("Contact Us")}
                      </a>
                    </li>
                    {/* <li>
                      <Link
                        to="/about"
                        className={`flex text-theme-button hover:text-theme-buttonHover py-2 ${
                          location.pathname === "/about" ? "font-extrabold" : ""
                        }`}
                      >
                        {t("About")}
                      </Link>
                    </li> */}
                    {/*<li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                      <span className="flex text-gray-600 dark:text-gray-400 py-2">
                        Resources
                      </span>
                      <ul className="pl-4">
                        <li>
                          <Link
                            to="/help"
                            className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Help center
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/404"
                            className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            404
                          </Link>
                        </li>
                      </ul>
                    </li>*/}
                    {/*<li>
                      <Link
                        to="/contact"
                        className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                      >
                        Request code
                      </Link>
                    </li>*/}
                  </ul>
                </div>
              </nav>
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
