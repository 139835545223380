import React from "react";

function AboutPartial({ t }) {
  return (
    <section className="relative border-t border-transparent dark:border-gray-800">
      {/* Background gradient */}
      <div
        className="absolute inset-0 h-128 dark:opacity-25 pointer-events-none"
        aria-hidden="true"
      ></div>
      {/* End background gradient */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16" data-aos="fade-up" data-aos-delay="100">
            <h2 className="h2 mb-4">{t("About")} {t("Beneficiary")}</h2>
            <p className="text-xl text-gray-600 dark:text-gray-400">
              {t("Charity Name")}
            </p>
          </div>

          {/* Section content */}
          <div>
            <div className="grid lg:grid-cols-3 gap-12 lg:gap-6" data-aos="fade-up" data-aos-delay="200">
              {/* Category buttons */}
              <div className="lg:col-span-1 lg:pr-16 flex flex-wrap justify-center lg:flex-col lg:justify-start -m-1 lg:mx-0 my-auto self-center">
                <img src={require("../logo/image3-sos.jpeg")} className="w-full self-center" alt="logo-charity" />
              </div>

              {/* Videos */}
              <div className="lg:col-span-2 max-w-sm mx-auto md:max-w-3xl lg:max-w-none flex flex-col justify-center">
                <p className="mb-4 text-center md:text-left">
                  {t("Charity About 1")}
                </p>
                <p className="mb-4 text-center md:text-left">
                  {t("Charity About 2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutPartial;
