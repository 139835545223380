import axios from "axios";
import {
  FETCH_LEADERBOARD_COMMENCED,
  FETCH_LEADERBOARD_SUCCESS,
  FETCH_LEADERBOARD_FAILURE,
} from "./types";

export const fetchLeaderboardList = (challengeId) => {
  let url = `https://aktivin.et.r.appspot.com/leaderboard/simple/${challengeId}`;
  return (dispatch) => {
    dispatch({ type: FETCH_LEADERBOARD_COMMENCED });
    axios
      .get(url)
      .then((leaderboard) => {
        fetchLeaderboardSuccess(dispatch, leaderboard.data);
      })
      .catch(() => fetchLeaderboardFailed(dispatch));
  };
};

const fetchLeaderboardSuccess = (dispatch, data) => {
  dispatch({ type: FETCH_LEADERBOARD_SUCCESS, payload: data });
};

const fetchLeaderboardFailed = (dispatch) => {
  dispatch({ type: FETCH_LEADERBOARD_FAILURE });
};
