import axios from "axios";
import qs from "qs";
import {
  PROCESS_DONATION_COMMENCED,
  PROCESS_DONATION_FAILURE,
  PROCESS_DONATION_SUCCESS,
  FETCH_DONATION_GRAPH_COMMENCED,
  FETCH_DONATION_GRAPH_SUCCESS,
  FETCH_DONATION_GRAPH_FAILURE,
  FETCH_MILEAGE_TOTAL_COMMENCED,
  FETCH_MILEAGE_TOTAL_SUCCESS,
  FETCH_MILEAGE_TOTAL_FAILURE,
} from "./types";

export const processDonationTransaction = ({
  programId,
  programName,
  name,
  email,
  contactNo,
  processingFee,
  totalAmount,
  paymentMethod,
  isAnonymousFlag,
  donationDetail,
  deliveryCourier,
  shippingFee,
  address,
  recipientName,
  recipientContact,
  localitiesId,
  postcode,
  paymentMethodType,
}) => {
  let url = `https://aktivin.et.r.appspot.com/donation/insert/${paymentMethodType}`;
  // let url = `http://localhost:8080/donation/insert/${paymentMethodType}`;
  var myObject = {
    program_id: programId,
    programName,
    name,
    email,
    processing_fee: processingFee ? processingFee : 0,
    total_amount: totalAmount,
    preferred_payment: paymentMethod,
    is_anonymous: isAnonymousFlag,
    donationDetail,
    delivery_courier: deliveryCourier ? deliveryCourier : "",
    shipping_fee: shippingFee ? shippingFee : 0,
    address: address ? address : "",
    recipient_name: recipientName,
    recipient_contact: recipientContact,
    localities_id: localitiesId ? localitiesId : null,
    postcode: postcode ? postcode : "",
  };

  return (dispatch) => {
    dispatch({ type: PROCESS_DONATION_COMMENCED });
    axios
      .post(url, myObject, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((success) => {
        processDonationTransactionSuccess(dispatch, success.data);
      })
      .catch((error) => {
        processDonationTransactionFailed(dispatch);
      });
  };
};

export const processDonationTransactionWithBenihbaik = ({
  programId,
  benihBaikCampaignId,
  programName,
  name,
  email,
  contactNo,
  processingFee,
  totalAmount,
  paymentMethod,
  isAnonymousFlag,
  donationDetail,
  deliveryCourier,
  shippingFee,
  address,
  recipientName,
  recipientContact,
  localitiesId,
  postcode,
  paymentMethodType,
  linkBackPartner
}) => {
  let url = `https://aktivin.et.r.appspot.com/donation/insert/benihbaik/production`;
  // let url = `http://192.168.18.12/donation/insert/benihbaik/production`;
  var myObject = {
    program_id: programId,
    campaign_id: benihBaikCampaignId,
    programName,
    name,
    email,
    contact_no: contactNo,
    processing_fee: processingFee ? processingFee : 0,
    total_amount: totalAmount,
    preferred_payment: paymentMethod,
    is_anonymous: isAnonymousFlag,
    donationDetail,
    delivery_courier: deliveryCourier ? deliveryCourier : "",
    shipping_fee: shippingFee ? shippingFee : 0,
    address: address ? address : "",
    recipient_name: recipientName,
    recipient_contact: recipientContact,
    localities_id: localitiesId ? localitiesId : null,
    postcode: postcode ? postcode : "",
    paymentMethodPartnerId: paymentMethodType,
    linkBackPartner
  };

  return (dispatch) => {
    dispatch({ type: PROCESS_DONATION_COMMENCED });
    axios
      .post(url, myObject, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((success) => {
        processDonationTransactionSuccess(dispatch, success.data);
      })
      .catch((error) => {
        processDonationTransactionFailed(dispatch);
      });
  };
};

const processDonationTransactionSuccess = (dispatch, message) => {
  dispatch({ type: PROCESS_DONATION_SUCCESS, payload: message });
};

const processDonationTransactionFailed = (dispatch) => {
  dispatch({ type: PROCESS_DONATION_FAILURE });
};

export const fetchDonationGraph = (donationProgramId) => {
  let url = `https://aktivin.et.r.appspot.com/donation/graph/${donationProgramId}`;

  return (dispatch) => {
    dispatch({ type: FETCH_DONATION_GRAPH_COMMENCED });
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((success) => {
        fetchDonationGraphSuccess(dispatch, success.data);
      })
      .catch(() => fetchDonationGraphFailure(dispatch));
  };
};

const fetchDonationGraphSuccess = (dispatch, message) => {
  dispatch({ type: FETCH_DONATION_GRAPH_SUCCESS, payload: message });
};

const fetchDonationGraphFailure = (dispatch) => {
  dispatch({ type: FETCH_DONATION_GRAPH_FAILURE });
};

export const fetchMileageTotal = (donationProgramId) => {
  let url = `https://aktivin.et.r.appspot.com/donation/mileage/${donationProgramId}`;
  
  return (dispatch) => {
    dispatch({ type: FETCH_MILEAGE_TOTAL_COMMENCED });
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((success) => {
        fetchMileageTotalSuccess(dispatch, success.data);
      })
      .catch(() => fetchMileageTotalFailure(dispatch));
  };
};

const fetchMileageTotalSuccess = (dispatch, message) => {
  dispatch({ type: FETCH_MILEAGE_TOTAL_SUCCESS, payload: message });
};

const fetchMileageTotalFailure = (dispatch) => {
  dispatch({ type: FETCH_MILEAGE_TOTAL_FAILURE });
};