import React from "react";

function Stats({t}) {
  const buttons = [
    {
      title: "Panduan Donasi",
      cssColorClass: "bg-theme-buttonSupp hover:bg-theme-buttonSuppHover",
      url: "https://aktivin.id/pwc-donation-guide",
    },
    {
      title: "Panduan Pengguna",
      cssColorClass: "bg-theme-button hover:bg-theme-buttonHover",
      url: "https://aktivin.id/pwc-user-guide/",
    },
  ];
  return (
    <section className="relative">
      {/* Background gradient (light version only) */}
      <div
        className="absolute bottom-0 left-0 right-0 h-128 pointer-events-none -z-10"
        aria-hidden="true"
      ></div>
      {/* End background gradient (light version only) */}
      <div className="pt-12 md:pt-18 relative max-w-3xl mx-auto px-4 sm:px-6" data-aos="fade-up" data-aos-delay="150">
        <div className="pb-12 md:pb-20">
          <p class="text-center text-gray-600 mb-8">
            {t("Guide Text 1")}{" "}
            <strong>{t("Event Name")}</strong>{" " + t("Guide Text 2")}.
          </p>
          <div
            className="grid grid-cols-1 gap-4 lg:gap-6 md:grid-cols-2 text-center"
            data-aos-id-stats
          >
            {buttons.map((buttonInfo) => {
              const { cssColorClass, title, url } = buttonInfo;
              return (
                <button
                  onClick={() => window.open(url, '_blank')}
                  className={`btn ${cssColorClass} py-4 px-2 shadow-2xl`}
                  data-aos="fade-down"
                  data-aos-anchor="[data-aos-id-stats]"
                >
                  <div className="text-xl font-bold tracking-tighter text-white">
                    {t(title)}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
