export const REGISTER_PERFORMER_COMMENCED = "REGISTER_PERFORMER_COMMENCED";
export const REGISTER_PERFORMER_SUCCESS = "REGISTER_PERFORMER_SUCCESS";
export const REGISTER_PERFORMER_FAILURE = "REGISTER_PERFORMER_FAILURE";
export const LIST_PERFORMER_COMMENCED = "LIST_PERFORMER_COMMENCED";
export const LIST_PERFORMER_SUCCESS = "LIST_PERFORMER_SUCCESS";
export const LIST_PERFORMER_FAILURE = "LIST_PERFORMER_FAILURE";
export const RESET_NEW_PERFORMER_DATA = "RESET_NEW_PERFORMER_DATA";

export const ENROL_CHALLENGE_SUCCESS = "enrol_challenge_success";
export const ENROL_CHALLENGE_FAILURE = "enrol_challenge_failed";
export const ENROL_CHALLENGE_COMMENCED = "enrol_challenge_commenced";
export const RESET_REGISTRANT_INFO = "reset_registrant_info";

export const FETCH_CHALLENGE_GROUP_COMMENCED =
  "fetch_challenge_group_commenced";
export const FETCH_CHALLENGE_GROUP_SUCCESS = "fetch_challenge_group_success";
export const FETCH_CHALLENGE_GROUP_FAILURE = "fetch_challenge_group_failed";
export const FETCH_DONATION_GRAPH_COMMENCED = "fetch_donation_graph_commenced";
export const FETCH_DONATION_GRAPH_SUCCESS = "fetch_donation_graph_success";
export const FETCH_DONATION_GRAPH_FAILURE = "fetch_donation_graph_failure";
export const FETCH_MILEAGE_TOTAL_COMMENCED = "fetch_mileage_total_commenced";
export const FETCH_MILEAGE_TOTAL_SUCCESS = "fetch_mileage_total_success";
export const FETCH_MILEAGE_TOTAL_FAILURE = "fetch_mileage_total_failure";

export const FETCH_DONATION_ADDONS_COMMENCED =
  "fetch_donation_addons_commenced";
export const FETCH_DONATION_ADDONS_SUCCESS = "fetch_donation_addons_success";
export const FETCH_DONATION_ADDONS_FAILURE = "fetch_donation_addons_failure";
export const FETCH_GROUP_MEMBERS_COMMENCED = "fetch_group_members_commenced";
export const FETCH_GROUP_MEMBERS_SUCCESS = "fetch_group_members_success";
export const FETCH_GROUP_MEMBERS_FAILURE = "fetch_group_members_failure";
export const FETCH_GROUP_DONATIONS_COMMENCED =
  "fetch_group_donations_commenced";
export const FETCH_GROUP_DONATIONS_SUCCESS = "fetch_group_donations_success";
export const FETCH_GROUP_DONATIONS_FAILURE = "fetch_group_donations_failure";

export const FETCH_PROVINCE_LIST_COMMENCED = "fetch_province_list_commenced";
export const FETCH_PROVINCE_LIST_SUCCESS = "fetch_province_list_success";
export const FETCH_PROVINCE_LIST_FAILURE = "fetch_province_list_failure";
export const FETCH_REGENCY_LIST_COMMENCED = "fetch_regency_list_commenced";
export const FETCH_REGENCY_LIST_SUCCESS = "fetch_regency_list_success";
export const FETCH_REGENCY_LIST_FAILURE = "fetch_regency_list_failure";
export const FETCH_DISTRICT_LIST_COMMENCED = "fetch_district_list_commenced";
export const FETCH_DISTRICT_LIST_SUCCESS = "fetch_district_list_success";
export const FETCH_DISTRICT_LIST_FAILURE = "fetch_district_list_failure";
export const FETCH_VILLAGE_LIST_COMMENCED = "fetch_village_list_commenced";
export const FETCH_VILLAGE_LIST_SUCCESS = "fetch_village_list_success";
export const FETCH_VILLAGE_LIST_FAILURE = "fetch_village_list_failure";
export const FETCH_RAJAONGKIR_RID_COMMENCED = "fetch_rajaongkir_rid_commenced";
export const FETCH_RAJAONGKIR_RID_SUCCESS = "fetch_rajaongkir_rid_success";
export const FETCH_RAJAONGKIR_RID_FAILURE = "fetch_rajaongkir_rid_failure";
export const CALCULATE_DELIVERY_COST_COMMENCED =
  "calculate_delivery_cost_commenced";
export const CALCULATE_DELIVERY_COST_SUCCESS =
  "calculate_delivery_cost_success";
export const CALCULATE_DELIVERY_COST_FINISHED =
  "calculate_delivery_cost_finished";
export const CALCULATE_DELIVERY_COST_FAILURE =
  "calculate_delivery_cost_failure";

export const REDEEM_OFFER_SUCCESS = "redeem_offer_success";
export const REDEEM_OFFER_FAILURE = "redeem_offer_failed";
export const REDEEM_OFFER_COMMENCED = "redeem_offer_commenced";
export const PAYMENT_PROCESSING_COMMENCED = "payment_processing_commenced";
export const PAYMENT_PROCESSING_SUCCESS = "payment_processing_success";
export const PAYMENT_PROCESSING_FAILURE = "payment_processing_failure";
export const SET_PAYMENT_INFORMATION = "set_payment_information";

export const REGISTER_NEW_USER_COMMUNITY_ID_SUCCESS =
  "register_new_user_community_success";
export const REGISTER_NEW_USER_COMMUNITY_ID_FAILURE =
  "register_new_user_community_failure";
export const REGISTER_NEW_USER_COMMUNITY_ID_COMMENCED =
  "register_new_user_community_commenced";

export const FETCH_CHALLENGE_PARTNER_SUCCESS =
  "fetch_challenge_partner_success";
export const FETCH_CHALLENGE_PARTNER_FAILURE = "fetch_challenge_partner_failed";
export const FETCH_CHALLENGE_PARTNER_COMMENCED =
  "fetch_challenge_partner_commenced";

export const FETCH_CUSTOM_GRAPH_COMMENCED = "fetch_custom_graph_commenced";
export const FETCH_CUSTOM_GRAPH_SUCCESS = "fetch_custom_graph_success";
export const FETCH_CUSTOM_GRAPH_FAILURE = "fetch_custom_graph_failure";
export const CHECK_CORP_ID_COMMENCED = "check_corp_id_commenced";
export const CHECK_CORP_ID_SUCCESS = "check_corp_id_success";
export const CHECK_CORP_ID_FAILURE = "check_corp_id_failure";
export const CREATE_NEW_GROUP_COMMENCED = "create_new_group_commenced";
export const CREATE_NEW_GROUP_SUCCESS = "create_new_group_success";
export const CREATE_NEW_GROUP_FAILURE = "create_new_group_failure";
export const SAVE_ADDRESS_COMMENCED = "save_address_commenced";
export const SAVE_ADDRESS_SUCCESS = "save_address_success";
export const SAVE_ADDRESS_FAILURE = "save_address_failure";

export const FETCH_LEADERBOARD_COMMENCED = "fetch_leaderboard_commenced";
export const FETCH_LEADERBOARD_SUCCESS = "fetch_leaderboard_success";
export const FETCH_LEADERBOARD_FAILURE = "fetch_leaderboard_failure";
export const FETCH_LEADERBOARD_IVI_COMMENCED = "fetch_leaderboard_ivi_commenced";
export const FETCH_LEADERBOARD_IVI_SUCCESS = "fetch_leaderboard_ivi_success";
export const FETCH_LEADERBOARD_IVI_FAILURE = "fetch_leaderboard_ivi_failure";
export const FETCH_LEADERBOARD_MVM_COMMENCED = "fetch_leaderboard_mvm_commenced";
export const FETCH_LEADERBOARD_MVM_SUCCESS = "fetch_leaderboard_mvm_success";
export const FETCH_LEADERBOARD_MVM_FAILURE = "fetch_leaderboard_mvm_failure";

export const PROCESS_DONATION_COMMENCED = "process_donation_commenced";
export const PROCESS_DONATION_SUCCESS = "process_donation_success";
export const PROCESS_DONATION_FAILURE = "process_donation_failure";