import React, { useState } from "react";
import { Link } from "react-router-dom"
import YouTube from "react-youtube";
import getYouTubeID from "get-youtube-id";

import Modal from "../utils/Modal";

function HeroHome({ t }) {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const buttons = [
    {
      title: t("Programme & Beneficiaries"),
      cssColorClass: "bg-theme-buttonSupp hover:bg-theme-buttonSuppHover text-white",
      url: "/programme",
      type: 'internal',
      windowType: '_self'
    },
    {
      title: t("FAQs"),
      cssColorClass: "bg-theme-buttonSupp hover:bg-theme-buttonSuppHover text-white",
      url: "/faq",
      type: 'internal',
      windowType: '_self'
    },
    {
      title: t("Racer Guide"),
      cssColorClass: "bg-theme-buttonSupp hover:bg-theme-buttonSuppHover text-white",
      url: "https://aktivin.id/pwc-racer-guide",
      type: 'external',
      windowType: '_blank'
    },
    {
      title: t("Donation Guide"),
      cssColorClass: "bg-theme-buttonSupp hover:bg-theme-buttonSuppHover text-white",
      url: "https://aktivin.id/pwc-donation-guide",
      type: 'external',
      windowType: '_blank'
    }
  ];

  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-20">
        <div className="pt-24 md:pt-30" data-aos="fade-down">
          {/* Logos */}
          <Link
            to="/donate"
            className="text-center md:text-right max-w-full"
          >
            <img src={require("../images/pwc-banner-v2.jpg")} className="mx-auto" />
          </Link>

          <div className="mx-auto max-w-4xl grid grid-cols-2 mt-12 md:mt-20 gap-5">
            {buttons.map(button => {
              const { url, cssColorClass, title, windowType, type } = button;

              if (type === "internal") {
                return (<Link to={url} className={`btn ${cssColorClass} py-4 px-2 shadow-2xl`}><button
                  onClick={() => window.open(url, windowType)}

                  data-aos="fade-down"
                  data-aos-anchor="[data-aos-id-stats]"
                >
                  <div className="text-sm md:text-xl tracking-tighter">
                    {title}
                  </div>
                </button>
                </Link>
                )
              } else {
                return (<button
                  onClick={() => window.open(url, windowType)}
                  className={`btn ${cssColorClass} py-4 px-2 shadow-2xl`}
                  data-aos="fade-down"
                  data-aos-anchor="[data-aos-id-stats]"
                >
                  <div className="text-sm md:text-xl tracking-tighter">
                    {title}
                  </div>
                </button>
                )
              }

            })}

          </div>

          <div className="mx-auto max-w-4xl grid grid-cols-1 mt-5 gap-5">
            {/* <Link to={"/donate"} className={`btn bg-theme-button py-4 px-2 shadow-2xl hover:bg-themebuttonHover`}>
              <button
                data-aos="fade-down"
                data-aos-anchor="[data-aos-id-stats]"
              >
                <div className="text-sm md:text-xl tracking-tighter">
                  {(t("Donate")).toUpperCase()}
                </div>
              </button>
            </Link> */}

            <p className="text-center">
              'While taking part in the race, always remember to protect yourself and others around you by knowing the facts and taking the appropriate precautions. Follow the advice
              that is provided by IBS and your local authority. Wear your mask, practise social distancing, avoid crowds, and always sanitise your hands.'
            </p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default HeroHome;
