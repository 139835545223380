import React from "react";
import i18n from "../i18n";

function AboutPartial({ t }) {
  const IDQNAList = [
    {
      q: "Bagaimana cara kerja dari Virtual Charity Race (disebut “program”) ini?",
      l: [
        { d: "Setiap tim memiliki target (kilometer) yang harus dicapai. Besarnya target tergantung kepada jumlah donasi yang diterima oleh masing-masing tim " },
        { d: "Semakin banyak Tim menerima donasi, semakin besar target yang harus dicapai. " },
      ],
      f: [{
        type: "text", value: [
          "Apabila Anda tidak menyukai tantangan berupa aktivitas fisik, Anda tetap dapat berpartisipasi sebagai donor atau penggalang dana, dengan membagikan tautan donasi ke kerabat/teman/lingkungan Anda."
        ]
      }]
    },
    {
      q: "Aktivitas apa saja yang diperbolehkan dalam program ini? Apakah saya boleh memilih lebih dari satu tipe aktivitas? Bagaimana sistem perhitungannya?",
      l: [
        { d: "Peserta dapat bebas untuk melakukan tipe aktivitas yang diinginkan selama periode program, baik berlari, bersepeda atau keduanya; artinya peserta dapat memilih satu atau dua aktivitas atau melakukan ketiganya. ", },
        {
          d: "Setiap jarak pada aktivitas yang dilakukan akan dikonversikan menjadi poin aktivitas yang rincian sebagai berikut:", l: [
            { h: "○ Lari atau jalan", d: "Setiap 1 km aktivitas lari atau jalan = 1 poin aktivitas. Peserta dapat mengkombinasikan lari dengan jalan, selama terus memantau jarak tempuh (km)." },
            { h: "○ Sepeda", d: "Setiap 3 km aktivitas bersepeda = 1 poin aktivitas." }
          ],
        },
        { d: "Setiap jarak desimal yang telah dilakukan (<1km) akan terus terakumulasi dengan aktivitas-aktivitas sebelumnya seiring dengan aktivitas yang dilakukan selama periode program berlangsung. Contoh: Anda telah berlari sejauh 3,6km. Berarti 0,6km akan diakumulasi ke dalam poin berikutnya. " },
      ],
    },

    {
      q: "Bagaimana cara mengkonversi donasi menjadi poin aktivitas? ",
      l: [
        { d: "Untuk setiap 1 (satu) poin donasi yang diterima oleh suatu Tim, peserta Tim tersebut harus “menebus” poin donasi dengan lari, bersepeda, atau jalan." },
        {
          d: "Contoh: ", l: [
            { d: "○ Jika tim Anda menerima donasi sebesar IDR 1.000.000, donasi tersebut menghasilkan 20 poin aktivitas. " },
            { d: "○ Poin aktivitas tersebut akan menjadi target yang harus dipenuhi tim Anda." },
            { d: "○ Jadi, tergantung tim Anda untuk menentukan strategi guna memenuhi target tersebut, baik dengan lari, bersepeda, atau jalan" }
          ]
        }
      ]
    },
    {
      q: "Siapa yang dapat berdonasi ke program ini? Siapa yang melakukan penggalangan dana?",
      l: [
        { d: "Semua orang (termasuk Anda, teman, keluarga dan rekan) dapat berdonasi atau melakukan penggalangan dana ke program ini. " },
        { d: "Saat berdonasi, jangan lupa untuk menyalurkan donasi tersebut ke team Anda. " },
        { d: "Donasi berlaku dalam kelipatan IDR 50,000. Setiap kelipatan IDR 50,000 akan dikonversi menjadi 1 (satu) poin aktivitas. " },
      ]
    },
    {
      q: "Kapan donasi dimulai?",
      a: ["Donasi akan dimulai bersamaan dengan dimulainya race dan akan berlangsung hingga race berakhir."]
    },

    {
      q: "Bagaimana setiap tim mendistribusikan aktivitas untuk mencapai target? ",
      a: ["Tidak ada peraturan pembagian khusus. Setiap tim dapat menentukan strateginya masing-masing. Idealnya, setiap tim berkoordinasi dan memberikan tugas sesuai dengan kemampuan beraktivitas masing-masing peserta dalam timnya."]
    },
    {
      q: "Bagaimana jika sampai menjelang akhir periode, masih tersisa target poin aktivitas (Km) yang belum terpenuhi?",
      a: ["Idealnya adalah semua tim dapat menyelesaikan target Km-nya sesuai dengan donasi yang terkumpul. Namun apabila kondisi ini terjadi, Panitia akan mengambil tindakan tertentu yang akan diinformasikan kemudian."]
    },
    {
      q: "Apa saja persiapan yang harus dilakukan sebelum mulai track aktivitas dengan aplikasi pada handphone?",
      l: [
        {d: `Pastikan baterai handphone cukup selama beraktivitas, sinyal handphone dalam keadaan baik (tidak melakukan aktivitas di area dekat dengan bangunan tinggi ataupun pohon tinggi, yang dapat mengganggu sinyal) dan sebaiknya pengaturan GPS di handphone diatur "high accuracy".`},
        {d: `Untuk penjelasan penyebab hasil data aktivitas yang buruk, dan cara mencegah hal terkait terjadi, kamu dapat membaca artikel dari Strava berikut ini.`},
      ],
      f: [{ type: "link", value: "https://support.strava.com/hc/en-us/articles/216917707-Bad-GPS-Data" }]
    },
    {
      q: "Bagaimana cara saya mengirimkan data aktivitas saya untuk dikonversi menjadi poin aktivitas? Kapan saya harus mengirimkan/melaporkan data tersebut?",
      a: [`Di aplikasi Aktivin, terdapat 2 (dua) cara terkait pengiriman data aktivitas.`],
      l: [
        { h: "Sinkronisasi aplikasi pihak ketiga (Contoh: Strava, Garmin, Fitbit)", d: "Maksimal 7 hari sejak aktivitas dilakukan" },
        { h: "Penambahan aktivitas secara manual", d: "Apabila aktivitas dilakukan di treadmil atau sepeda statis, maka untuk penambahan aktivitas secara manual, harap mengirimkan bukti foto (selfie dengan mesin yang menunjukkan hasil dari aktivitas tersebut)." }
      ],
      f: [{
        type: "text", value: [
          "Kamu dapat membaca panduan terkait penambahan data aktivitas secara lengkap melalui link berikut. Aktivitas yang ditambah melalui proses sinkronisasi akan diverifikasi otomatis melalui sistem Aktivin sedangkan aktivitas yang ditambahkan secara manual akan melalui proses verifikasi oleh admin Aktivin dan akan diverifikasi dalam maksimal 1x24 jam."
        ]
      }]
    },
    {
      q: "Jika terjadi perbedaan riwayat aktivitas sebenarnya dengan hasil ditrack aktivitas di aplikasi apakah bisa menambahkan jarak secara manual?",
      a: ["Aktivitas yang tidak/gagal tercatat dapat dikirimkan via aplikasi (melalui fitur tambah manual atau sinkronisasi) dan selanjutnya peserta diharapkan menghubungi CS Aktivin."]
    },
    {
      q: "Saya memiliki data aktivitas di Strava namun mengapa tidak dapat ditarik ke Aktivin?",
      a: ["Hal ini dapat disebabkan oleh 3 hal, yakni sebagai berikut :"],
      l: [
        { d: "Aktivitas ditambahkan secara manual di Strava" },
        { d: "Data aktivitas sudah pernah dimasukan ke Aktivin" },
        { d: "Kecepatan melebihi kecepatan wajar" }
      ],
      f: [
        { type: "text", value: ["Jika data aktivitasmu valid dilakukan namun tidak dapat masuk ke Aktivin, kamu dapat menghubungi Customer Service Aktivin."] },
        { type: "link", value: "(https://wa.me/628992988123)" }
      ]
    }
  ]

  const ENQNAList = [
    {
      q: "How does the Virtual Charity Race (the so-called “programme”) work?",
      // a: [`This program uses a team-based system with the type of 'Journey'. Participants will be grouped in a team according to their Line of Service (LoS), where each team has responsibility to raise donations (called "donation points"). The donations collected will be converted into activities (called "activity points" and 1 activity point = (multiples) IDR 50,000). Furthermore, the team must carry out activities in accordance with the number of activity points collected.`]
      l: [
        { d: "When the race starts, Participants will be grouped into teams." },
        { d: "Each team will have a target (in km) to achieve, and the target will depend on the amount of donations that each team receives." },
        { d: "The more donations that a team receives, the more targets that it will have to achieve." }
      ],
      f: [{
        type: "text", value: [
          "However, if you’re not up for/into the physical challenge, you can still participate as a donor oras a fundraiser, by sharing the donation link with your circle."
        ]
      }]
    },
    {
      q: "What activities are allowed in this programme? Can I do more than one type of activity? How is the calculation system?",
      l: [
        { d: "During the programme, participants can choose to run or cycle or both. Activities can be done anywhere you like, even from the comfort of your own home (e.g. on a treadmill or a static bike in your own backyard). Participants must always pay attention to the health protocols and maintain a safe distance.", },
        {
          d: "Each distance in the activity carried out will be converted into activity points which are detailed as follows:", l: [
            { h: "○ Running or walking", d: "Every 1km of running or walking = 1 activity point. You can combine running with walking, as long as you monitor the distance (Km)." },
            { h: "○ Cycling", d: "3km of cycling = 1 activity point." }
          ],
        },
        { d: "Each decimal distance that has been done (<1km) will continue to accumulate with previous activities along with the activities carried out during the programme period. Example: You have run 3.6km. Means that 0.6km will be accumulated in the next points." },
      ],
    },
    {
      q: "How to convert the donation into an activity point? ",
      l: [
        { d: "For every 1 (one) point donated to a Team, the members of the Team have to “redeem” the points by running or cycling or walking." },
        {
          d: "Example: ", l: [
            { d: "○ If your team receives a donation of IDR 1 Million, then it generates 20 activity points." },
            { d: "○ These 20 activity points will be your team targets to achieve." },
            { d: "○ So, it's up to your team on what is the strategy to complete the target, whether through cycling or running or walking." }
          ]
        }
      ]
    },
    {
      q: "Who can donate to this programme? Who should be raising donations?",
      l: [
        { d: "Everyone (including yourself, friends, families and colleagues) can support this initiative as donors or fundraisers." },
        { d: "Don’t forget to ask others to donate to your team." },
        { d: "Donors can donate money in multiples of IDR 50,000; each multiple of IDR 50,000 willthen be converted into 1 (one) activity point." },
      ]
    },
    {
      q: "When will the donations start?",
      a: ["Donations will start at the same time as the race, and they can be made until the race ends."]
    },
    {
      q: "How does the team distribute the activity to achieve the target? ",
      a: [
        "There are no special rules. Each team can play their own strategy. Ideally, each member of the team should coordinate and distribute the activities according to each participant's ability to complete them.",
      ]
    },
    {
      q: "What if the team can not fulfill the target by the end of the period?",
      a: [
        "If this shortfall occurs, the Committee will take certain actions that will be briefed later.",
      ]
    },
    {
      q: "What are the preparations before starting an activity track with an application on a cellphone?",
      l: [
        { d: `Make sure the cell phone battery is sufficient during the activity, the cell phone signal is in good condition (avoid areas close to tall buildings or tall trees, which can interfere with the signal) and it is better if the GPS setting on the cellphone is set to "high accuracy".` },
        { d: `For an explanation of the causes of poor activity data results, and how to prevent related things from happening, you can read the following article from Strava.` }
      ],
      f: [{ type: "link", value: "https://support.strava.com/hc/en-us/articles/216917707-Bad-GPS-Data" }]
    },
    {
      q: "How do I send my activity data to be converted into activity points? When do I have to submit the data? ",
      a: [`In Aktivin, there are 2 (two) ways related to sending activity data.`],
      l: [
        { h: "Synchronization of third party applications (Example: Strava, Garmin, Fitbit)", d: "Maximum of 7 days from the time the activity is carried out" },
        { h: "Add activities manually (i.e. if you are using other applications like Nike Run Club)", d: "If the activity is carried out on a treadmill or stationary bike, then to manually add the activities, please send photo proof (selfie with a machine that shows the results of the activity).", }
      ],
      f: [{
        type: "text", value: [
          "You can read the complete guide regarding adding activity data via the following link. Activities added through the synchronization process will be verified automatically through the Aktivin system, while activities added manually will go through the verification process by the Aktivin admin and will be verified within a maximum of 1x24 hours."
        ]
      }]
    },
    {
      q: "If there is a difference between the actual activity record and the activity tracked result in the application, can I add the data manually?",
      a: ["Activities that are not / fail to be recorded can be sent via the application (choose the added manual or synchronization feature) and then participants must contact Activin CS."]
    },
    {
      q: "I have activity data on Strava but why can't it be pulled into Aktivin?",
      a: ["This can be caused by 3 things: "],
      l: [
        { d: "Activity added manually in Strava " },
        { d: "Activity data has been entered into Aktivin" },
        { d: "The activity pace is not logic" }
      ],
      f: [
        { type: "text", value: ["If your activity data is valid but still can't enter Aktivin, you can contact Aktivin Customer Service."] },
        { type: "link", value: "(https://wa.me/628992988123)" }
      ]
    },
    // {
    //   q: "What if until the end of the period, there are still unfulfilled target activity points (Km)? ",
    //   a: ["Ideally, all teams should complete their Km target according to the donations collected. However, if this condition occurs, the Committee will take certain actions which will be informed later."]
    // }
  ]

  return (
    <section className="relative border-t border-transparent dark:border-gray-800">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16" data-aos="fade-down" data-aos-delay="100">
            <h2 className="mb-4 text-3xl lg:text-6xl font-heading">
              {t("Frequently Asked Questions")} (FAQ)
            </h2>
          </div>

          <ol data-aos="fade-up" data-aos-delay="150">
            {(i18n.language === "en" ? ENQNAList : IDQNAList).map(qna => {
              const { q, a, l, f } = qna;

              return (
                <li className="text-gray-600 mb-4 ml-6">
                  <div className="font-semibold mb-2 mt-10">
                    Q: {q}
                  </div>
                  <div className="text-gray-600 mb-4">
                    <div className="mb-2">
                      {a && a.map(aChild => {
                        return <p className="mb-1">{aChild}</p>
                      })}
                      {l && (
                        <ul className="mb-2 list-disc ml-5">
                          {l.map(p => {
                            return (
                              <li>
                                <div className="font-semibold">
                                  {p.h}
                                </div>
                                <div>
                                  {p.d}
                                  <ul className="mt-1 mb-2 ml-3">
                                    {p && p.l && p.l.map(lc => {
                                      return (
                                        <li className="mb-1">
                                          <div className="font-semibold">
                                            {lc.h}
                                          </div>
                                          <div className="ml-4">
                                            {lc.d}
                                          </div>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                    {f && f.map(fChild => {
                      return (
                        <div className="mb-2">
                          {fChild.type === "text" ? fChild.value && fChild.value.map(fChildVal => {
                            return <p className="mb-1">{fChildVal}</p>
                          }) : fChild.type === "link" ? (
                            <a href={f.value} className="text-blue-500 underline">{fChild.value}</a>
                          ) : null}
                        </div>
                      )

                    })}

                  </div>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    </section>
  );
}

export default AboutPartial;
