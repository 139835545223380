import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowUp,
  faTimes,
  faShoppingCart,
  faMinusCircle,
  faPlusCircle,
  faSave,
  faQrcode,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode.react";
import getYouTubeID from "get-youtube-id";

// Import Swiper
import Swiper, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";

// components
import Modal from "../utils/Modal";
import { formatMoney, validateEmail, validateContactNo } from "../helpers";
import i18n from "../i18n";

// actions
import {
  fetchChallengeGroupInformation,
  fetchGroupDonations,
  processDonationTransactionWithBenihbaik,
  fetchGroupMembers
} from "../actions";
import moment from "moment";

// Swiper.use([Autoplay, Navigation]);

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }

// function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(
//     getWindowDimensions()
//   );

//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return windowDimensions;
// }

function TeamList({ t }) {
  // constants declaration
  // const { height, width } = useWindowDimensions();
  const donationPerPts = 50000;
  
  const feeBorneByOrganizer = false;

  const openDonationTimeStamp = 1;
  // const openDonationTimeStamp = 1629219600;
  const closeDonationTimeStamp = 1638291599;
  const paymentMethods = [
    {
      label: t("Manual Transfer") + " BCA",
      aktivinPaymentId: 1,
      type: "manual",
      value: "L1nDpajKCW9LcwiAdVy1IJ",
    },
    {
      label: t("Manual Transfer") + " Mandiri",
      aktivinPaymentId: 1,
      type: "manual",
      value: "BWrYN2Gc1l928jHnNS3zkv"
    },
    {
      label: t("Credit / Debit Card"),
      aktivinPaymentId: 6,
      type: "online",
      feeFixedComp: 2000,
      value: "HkfnaxVj88aDx5TbZ52yfb"
    },
    // {
    //   label: t("Virtual Account") + " BCA",
    //   aktivinPaymentId: 7,
    //   type: "online",
    //   feeFixedComp: 4000,
    //   value: "EEpecD8aDrx8tuwN7uCI4R"
    // },
    {
      label: t("Virtual Account") + " " + t("Other Banks"),
      aktivinPaymentId: 7,
      type: "online",
      feeFixedComp: 4500,
      value: "I1o1OqPzsr05Gh2Vt7eKHK"
    },
    {
      label: "Go-Pay",
      aktivinPaymentId: 8,
      type: "online",
      feeVarComp: 0.02,
      value: "CgjXqEC5haMJUb7w5h9E4z"
    },
    {
      label: "OVO",
      aktivinPaymentId: 8,
      type: "online",
      feeVarComp: 0.015,
      value: "HcdkxjtHIDeAp2tszWbewM"
    },
  ];

  // history instantiation
  let history = useHistory();

  // Hooks instantiation
  const dispatch = useDispatch();

  // State instantiation
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [donationFee, setDonationFee] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [isModalDonorOpen, setIsModalDonorOpen] = useState(false);
  const [isModalManualOpen, setIsModalManualOpen] = useState(false);
  const [isDonorFormFilled, setIsDonorFormFilled] = useState(false);
  const [isAgreed1, setIsAgreed1] = useState(false);
  const [isAgreed2, setIsAgreed2] = useState(false);
  const [foundIndex, setFoundIndex] = useState(-1);
  const [tempItem, setTempItem] = useState(null);
  const [showIndex, setShowIndex] = useState(0);
  const [carousel, setCarousel] = useState(null);
  const [totalDonationPts, setTotalDonationPts] = useState(0);
  const [totalActivityPts, setTotalActivityPts] = useState(0);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorContactNo, setErrorContactNo] = useState(false);

  const [donorName, setDonorName] = useState("");
  const [donorEmail, setDonorEmail] = useState("");
  const [donorContactNo, setDonorContactNo] = useState("");
  const [chosenPaymentMethod, setChosenPaymentMethod] = useState("");
  const [isAnonymousFlag, setIsAnonymousFlag] = useState(false);

  // Props from reducers
  const runners = useSelector((state) => state.challenge.groupInfo);
  const groupMembers = useSelector((state) => state.challenge.groupMembers);
  const loadingGroupMembers = useSelector(
    (state) => state.challenge.loadingGroupMembers
  );
  const groupDonations = useSelector((state) => state.challenge.groupDonations);
  const donationProgramId = useSelector((state) => state.config.donationId);
  const donationProgramName = useSelector((state) => state.config.programName);
  const donationResponse = useSelector((state) => state.donation.donationResponse);
  const loadingDonation = useSelector((state) => state.donation.loadingDonation);
  const challengeId = useSelector((state) => state.config.challengeId);
  const benihBaikCampaignId = useSelector((state) => state.config.campaignId);
  const homepageUrl = useSelector((state) => state.config.homepageUrl);

  // component did mount
  useEffect(() => {
    // code to run on component mount
    dispatch(fetchChallengeGroupInformation(donationProgramId));
  }, []);

  useEffect(() => {
    if (!loadingDonation && donationResponse && !donationResponse.error) {
      window.location.href = donationResponse.payment_url;
    }
  }, [donationResponse, loadingDonation]);

  useEffect(() => {
    if (runners && runners.length > 0) {
      var totalAllDonation = 0,
        totalBurnedPackage = 0;

      runners.map((team) => {
        if (team.packageDonated) {
          var donatedPoint = parseInt(team.packageDonated);
          var burnedPoint = parseInt(team.pointBurned);

          totalAllDonation += team.packageDonated ? donatedPoint : 0;
          totalBurnedPackage += team.pointBurned ? burnedPoint : 0;
        }
      });

      setTotalDonationPts(totalAllDonation);
      setTotalActivityPts(totalBurnedPackage);
    }
  }, [runners]);

  useEffect(() => {
    let newTotalQty = 0;

    selectedTeams.map((team) => {
      newTotalQty += parseInt(team.qty);
    });
    setTotalQty(newTotalQty);
  }, [selectedTeams]);

  useEffect(() => {
    if (selectedTeam) {
      let newFoundIndex = selectedTeams.findIndex(
        (x) => x.id === selectedTeam.id
      );

      setFoundIndex(newFoundIndex);
    }
  }, [selectedTeam, selectedTeams]);
  //up till here

  useEffect(() => {
    if (
      donorName.length < 1 ||
      donorEmail.length < 1 ||
      donorContactNo.length < 1 ||
      chosenPaymentMethod === ""
    ) {
      setIsDonorFormFilled(false);
    } else {
      setIsDonorFormFilled(true);
    }
  }, [donorName, donorEmail, donorContactNo, chosenPaymentMethod]);

  useEffect(() => {
    if (chosenPaymentMethod && chosenPaymentMethod !== "") {
      let processingFee = 0;
      var paymentMethodInfo = paymentMethods.find(
        (x) => x.value.toString() == chosenPaymentMethod.toString()
      );

      processingFee +=
        paymentMethodInfo && paymentMethodInfo.feeFixedComp
          ? paymentMethodInfo.feeFixedComp
          : 0;
      processingFee +=
        paymentMethodInfo && paymentMethodInfo.feeVarComp
          ? totalQty * paymentMethodInfo.feeVarComp
          : 0;
      processingFee +=
        processingFee *
        (paymentMethodInfo && paymentMethodInfo.vatComp
          ? paymentMethodInfo.vatComp
          : 0);

      setDonationFee(processingFee)
    }
  }, [chosenPaymentMethod, totalQty])

  // scrolling to top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const showInfoModal = (team) => {
    setSelectedTeam(team);
    setIsModalInfoOpen(true);
  };

  const hideInfoModal = () => {
    setSelectedTeam(null);
    setShowIndex(0);
    setCarousel(null);
    setIsModalInfoOpen(false);
  };

  const showDonorModal = (team) => {
    setSelectedTeam(team);
    // if (selectedTeams.findIndex((x) => x.id === team.id) > -1) {
    //   setTempItem(
    //     selectedTeams[selectedTeams.findIndex((x) => x.id === team.id)]
    //   );
    // } else {
    //   setTempItem({ id: team.id, qty: "", comment: "", songPicked: "" });
    // }
    setIsModalDonorOpen(true);
  };

  const hideDonorModal = (flag) => {
    setSelectedTeam(null);
    // setTempItem(null);
    setIsModalDonorOpen(false);
  };

  const showManualModal = (team) => {
    setSelectedTeam(team);
    setIsModalManualOpen(true);
  };

  const hideManualModal = (team) => {
    setSelectedTeam(team);
    setIsModalManualOpen(false);
  };

  const onProceedButtonClick = () => {
    var paymentMethodInfo = paymentMethods.find(
      (x) => x.value.toString() == chosenPaymentMethod.toString()
    );

    dispatch(
      processDonationTransactionWithBenihbaik({
        programId: donationProgramId,
        programName: donationProgramName,
        name: donorName,
        email: donorEmail,
        contactNo: donorContactNo,
        processingFee: donationFee,
        totalAmount:
          totalQty + (feeBorneByOrganizer ? 0 : donationFee),
        paymentMethod: paymentMethodInfo.aktivinPaymentId,
        isAnonymousFlag,
        donationDetail: selectedTeams,
        paymentMethodType: chosenPaymentMethod,
        benihBaikCampaignId,
        linkBackPartner: homepageUrl + "donate"
      })
    );
  };

  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-col">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div
            className="max-w-3xl mx-auto text-center pb-12 md:pb-20"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            {/* <h1 className="h1 mb-4 pt-12">{t("Support Bands")}</h1> */}
            <h1 className="mb-4 text-3xl lg:text-6xl font-heading">{t("Donate")}</h1>
            <p className="text-xl text-gray-600 dark:text-gray-400">
              {t("donationSubtitle")}
            </p>
            <p className="text-lg text-gray-600 dark:text-gray-400 mt-3">
              {t("donationSubtitle2")}
            </p>
            <p className="text-lg text-gray-600 dark:text-gray-400">
              {t("donationSubtitle3")}
            </p>
          </div>

          <div
            className="w-full bg-white dark:bg-gray-900"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="w-full grid grid-cols-2 mb-2">
              <div className="flex items-center">
                <div>
                  <p className="font-semibold">{t("Total Donation Collected")}</p>
                  <p className="text-xs font-regular">{t("Total Donation Disclaimer")}</p>
                </div>
              </div>
              <div className="text-right font-bold text-xl flex items-center justify-end hidden md:flex">
                <div className="text-sm mr-2">IDR</div>
                {formatMoney(
                  // totalDonationPts * donationPerPts,
                  totalDonationPts,
                  0,
                  "",
                  "",
                  i18n.language
                )}
              </div>
              <div className="text-right font-bold text-xl flex items-center justify-end md:hidden">
                <div className="text-sm mr-2">IDR</div>
                {formatMoney(
                  // (totalDonationPts * donationPerPts) / 1000,
                  totalDonationPts / 1000,
                  0,
                  "",
                  "",
                  i18n.language
                )}
                <div className="text-sm">k</div>
              </div>
            </div>

            <div className="w-full flex items-center mb-4">
              <input
                type="text"
                value={searchTerm}
                className="form-input flex-grow text-sm"
                placeholder={t("Search your band by name")}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {/* Modal */}
          <Modal
            id="modal"
            ariaLabel="modal-gallery-process"
            show={isModalInfoOpen}
            handleClose={hideInfoModal}
            customHeight={"h-3/4"}
            customWidth={"w-3/4"}
          >
            <div className="relative px-3 pb-10 flex flex-col bg-white text-black">
              <div className="sticky pt-4 top-0 pb-2 text-2xl w-full flex px-3 bg-white">
                <p className="w-full font-bold text-xl text-gray-600 tracking-wider">
                  {selectedTeam && selectedTeam.name}
                </p>
                <button onClick={hideInfoModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              <div className="w-full">
                <div className="sticky pt-4 top-12 pb-3 tracking-wider font-bold text-sm uppercase mb-2 bg-white px-3">
                  {t("Team Logo")}
                </div>

                <div className="rounded overflow-hidden mx-auto w-full md:w-1/4 mb-4">
                  <img
                    src={
                      selectedTeam && selectedTeam.profile_img_url
                        ? selectedTeam.profile_img_url
                        : require("../logo/avatar-placeholder.png")
                    }
                  />
                </div>

                <div className="sticky pt-8 top-12 pb-3 tracking-wider font-bold text-sm uppercase mb-2 bg-white px-3">
                  {t("Team Members")}
                </div>

                <div className="text-sm px-3">
                  {groupMembers && groupMembers.length > 0 ? (
                    groupMembers.map((member) => {
                      return (
                        <div className="flex mb-4">
                          <img
                            className="h-24 w-24 object-stretch rounded-lg"
                            src={
                              // member && member.imageUrl
                              //   ? member.imageUrl
                              //   : require("../logo/avatar-placeholder.png")
                              require("../logo/avatar-placeholder.png")
                            }
                          />
                          <div className="ml-3 my-4 w-full">
                            <p className="font-semibold text-lg">
                              {member.name}
                              {/* Member Name */}
                            </p>
                            <div>
                              {member.packageRedeemed} {t("points")}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>{t("No members yet.")}</p>
                  )}
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            id="modal"
            ariaLabel="modal-gallery-process"
            show={isModalDonorOpen}
            handleClose={hideDonorModal}
            customHeight={"h-3/4"}
            customWidth={"w-3/4 md:w-1/2"}
          >
            <div className="relative pb-10 px-3 flex flex-col bg-white dark:bg-gray-800 text-black dark:text-white">
              <div className="sticky pt-4 top-0 pb-2 text-2xl w-full flex px-3 bg-white dark:bg-gray-800">
                <p className="font-heading w-full text-xl text-gray-600 dark:text-gray-400">
                  {selectedTeam && selectedTeam.name} (
                  {groupDonations && groupDonations.length} {t("donors")})
                </p>
                <button onClick={hideDonorModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              <div className="px-3">
                {groupDonations &&
                  groupDonations.map((donor, index) => {
                    return (
                      <div key={`donor-${index}`} className="my-4">
                        <p className="font-semibold text-lg">{t(donor.name)}</p>                        
                        <div>
                          {/* {donor.package} {t("points")} */}
                          IDR {formatMoney(donor.package, 2, "", "", i18n.language )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Modal>

          <Modal
            id="modal"
            ariaLabel="modal-gallery-process"
            show={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          >
            <div className="relative pt-3 pb-10 px-3 flex flex-col items-center bg-white dark:bg-gray-800 text-black dark:text-white">
              <div className="text-2xl z-10 rounded-full w-full flex justify-end px-3">
                <button onClick={() => setIsModalOpen(false)}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              <p className="text-center text-gray-600 dark:text-gray-400 font-heading md:text-3xl text-2xl">
                {t("Fill in below form to proceed to payment")}.
              </p>
              <p className="text-sm md:text-md text-center text-gray-600 dark:text-gray-400">
                {t("Disclaimer Donation")}
              </p>

              <div className="px-3 md:px-5 pt-6 max-w-2xl md:w-1/2">
                <form className="">
                  <label className="flex mt-3">
                    <input
                      id="anonymous"
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) => setIsAnonymousFlag(e.target.checked)}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                      {t("I would like to donate as Anonymous")}
                    </span>
                  </label>

                  <div className="grid grid-cols-3 mt-3">
                    <div className="flex items-center">{t("Name")}</div>
                    <div className="col-span-2">
                      <input
                        id="name"
                        type="text"
                        className="form-input w-full"
                        placeholder={t("Enter your full name")}
                        onBlur={(e) => setDonorName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 mt-3">
                    <div className="flex items-center">{t("Email")}</div>
                    <div className="col-span-2">
                      <input
                        id="email"
                        type="email"
                        className={`form-input w-full ${errorEmail
                          ? "border-1 border-red-500 text-red-500"
                          : ""
                          }`}
                        placeholder={t("Enter your email address")}
                        onBlur={(e) => {
                          if (!validateEmail(e.target.value)) {
                            setDonorEmail(e.target.value);
                            setErrorEmail(true);
                          } else {
                            setDonorEmail(e.target.value);
                            setErrorEmail(false);
                          }
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 mt-3">
                    <div className="flex items-center">{t("Contact No.")}</div>
                    <div className="col-span-2">
                      <input
                        id="contactNo"
                        type="text"
                        className={`form-input w-full ${errorContactNo
                          ? "border-1 border-red-500 text-red-500"
                          : ""
                          }`}
                        placeholder={t("Enter your contact number")}
                        onBlur={(e) => {
                          setDonorContactNo(e.target.value);
                          if (!validateContactNo(e.target.value)) {
                            setErrorContactNo(true);
                          } else {
                            setErrorContactNo(false);
                          }
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 mt-3">
                    <div className="flex items-center">
                      {t("Payment Method")}
                    </div>
                    <div className="col-span-2">
                      <select
                        className="form-input w-full"
                        onChange={(e) => {
                          setChosenPaymentMethod(e.target.value)
                        }}
                      >
                        <option value="">{t("Choose payment method...")}</option>
                        {paymentMethods.map((paymentMethod, index) => {
                          return (
                            <option
                              key={`payment-${index}`}
                              value={paymentMethod.value}
                            >
                              {paymentMethod.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {/* <label className="flex mt-3">
                    <input
                      id="waiver1"
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) => setIsAgreed1(e.target.checked)}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                      {t(
                        "I hereby declare that this donation is made by myself conscientiously"
                      )}

                    </span>
                  </label>

                  <label className="flex mt-3">
                    <input
                      id="waiver2"
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) => setIsAgreed2(e.target.checked)}
                    />
                    <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                      {t(
                        "I agree that donation cannot be reversed nor refunded once payment has been made"
                      )}

                    </span>
                  </label> */}



                </form>

                <div className="mt-5 mb-3">
                  <div className="font-heading text-lg font-bold">{t("Payment Information")}</div>
                  <div className="grid grid-cols-4">
                    <div className="col-span-2">{t("Donation Amount")}</div>
                    <div className="">IDR</div>
                    <div className="text-right">{formatMoney(
                      totalQty,
                      0,
                      "",
                      "",
                      i18n.language
                    )}
                    </div>

                    {donationFee && donationFee > 0 ? (
                      <div className="col-span-2">{t("Processing Fee")}</div>
                    ) : <div className="col-span-2" />}

                    {donationFee && donationFee > 0 ? (
                      <div className="">IDR</div>) : <div className="" />}

                    {donationFee && donationFee > 0 ? (
                      <div className="text-right flex-1">{formatMoney(
                        donationFee,
                        0,
                        "",
                        "",
                        i18n.language
                      )}</div>) : <div className="" />}

                    <div className="col-span-2 font-bold">{t("Amount Due")}</div>
                    <div className="font-bold">IDR</div>
                    <div className="text-right font-bold">{formatMoney(
                      (totalQty) + donationFee,
                      0,
                      "",
                      "",
                      i18n.language
                    )}</div>
                  </div>
                </div>
              </div>

              <button
                onClick={onProceedButtonClick}
                disabled={
                  // !(isDonorFormFilled && isAgreed1 && isAgreed2 && !errorEmail)
                  !(isDonorFormFilled && !errorEmail && !errorContactNo)
                }
                // className={`btn mt-6 ${isDonorFormFilled && isAgreed1 && isAgreed2 && !errorEmail
                className={`btn mt-6 ${isDonorFormFilled && !errorEmail && !errorContactNo
                  ? "bg-theme-button hover:bg-theme-buttonHover"
                  : "bg-gray-200"
                  } flex justify-center text-white`}
              >
                <span className="mr-2">
                  {t(loadingDonation ? "Loading..." : "Proceed")}
                </span>
                {!loadingDonation ? (
                  <FontAwesomeIcon icon={faArrowRight} />
                ) : null}
              </button>
            </div>
          </Modal>

          {/* Teams card grid */}
          <div
            className="flex-grow grid gap-3 grid-cols-1 md:grid-cols-3 pb-20"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            {(searchTerm
              ? runners.filter((x) =>
                x.name.toLowerCase().includes(searchTerm.toLowerCase()) || x.members.toLowerCase().includes(searchTerm.toLowerCase())
              )
              : runners
            ).map((team, index) => {
              const { id, profile_img_url: logoUrl, name, pointBurned, packageDonated, remarks } = team;
              let currTeamIndex = selectedTeams.findIndex((x) => x.id === id);
              let qty = "";
              if (currTeamIndex > -1) qty = selectedTeams[currTeamIndex].qty;
              let targetCategory = "";
              try {
                targetCategory = (((remarks.split(";")[1]).split(":")[1]).split("/")[0]).replace("km", "");
              }
              catch (ex) {
                targetCategory = ""
              }

              return (
                <div
                  key={id.toString()}
                  className={`overflow-hidden dark:bg-gray-700 shadow flex flex-col items-center`}
                >
                  <div className="flex-1 items-center grid grid-cols-2">
                    <div className="overflow-hidden m-4 flex items-center justify-center">
                      <img src={logoUrl} className="object-contain h-48 rounded" />
                      {/* <img src={require("../logo/avatar-placeholder.png")} /> */}
                    </div>
                    <div className="flex flex-col justify-center p-3">
                      <div className="grid grid-cols-5">
                        <div className="text-xs col-span-2 flex items-center">
                          {t("Activity Pts.")}
                        </div>
                        <div className="text-right col-span-3 font-bold text-lg">
                          {pointBurned}
                        </div>
                      </div>
                      <div className="grid grid-cols-5">
                        <div className="text-xs col-span-2 flex items-center">
                          {t("Total Donation Collected")}
                        </div>
                        <div className="text-right font-bold text-lg col-span-3">
                          {/* IDR {formatMoney(packageDonated * donationPerPts, 0, "", "", i18n.locale)} */}
                          IDR {formatMoney(packageDonated, 0, "", "", i18n.locale)}
                        </div>
                      </div>

                      {moment().unix() >= openDonationTimeStamp && moment().unix() < closeDonationTimeStamp ?
                        <div>
                          <div className="text-xs col-span-2 my-2">
                            {t("Donation Package")}
                          </div>
                          <div className="grid grid-cols-5">
                            <a
                              className="flex justify-center items-center"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                let newSelectedTeams = [...selectedTeams];
                                if (currTeamIndex > -1) {
                                  if (newSelectedTeams[currTeamIndex].qty === donationPerPts)
                                    newSelectedTeams.splice(currTeamIndex, 1);
                                  else {
                                    newSelectedTeams[currTeamIndex].qty =
                                      newSelectedTeams[currTeamIndex].qty - donationPerPts;
                                  }

                                  setSelectedTeams(newSelectedTeams);
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faMinusCircle}
                                className="text-theme-button"
                              />
                            </a>
                            <div className="col-span-3">
                              <input
                                type="text"
                                className="border-1 w-full border-gray-200 dark:border-gray-600 hover:border-theme-button text-center text-xl font-bold dark:bg-gray-700"
                                value={qty}
                                onChange={(e) => {
                                  let newSelectedTeams = selectedTeams.slice();
                                  if (
                                    currTeamIndex > -1 &&
                                    parseInt(e.target.value) >= 1
                                  ) {
                                    newSelectedTeams[currTeamIndex].qty = parseInt(
                                      e.target.value
                                    );
                                  } else if (
                                    currTeamIndex > -1 ||
                                    isNaN(parseInt(e.target.value))
                                  ) {
                                    newSelectedTeams.splice(currTeamIndex, 1);
                                  } else {
                                    newSelectedTeams.push({
                                      id: team.id,
                                      team: team.id,
                                      teamName: team.name,
                                      qty: parseInt(e.target.value),
                                      // pricePerItem: donationPerPts,
                                      pricePerItem: 1
                                    });
                                  }
                                  setSelectedTeams(newSelectedTeams);
                                }}
                                onBlur={(e) => {
                                  let newSelectedTeams = selectedTeams.slice();
                                  if (
                                    currTeamIndex > -1 &&
                                    e.target.value % donationPerPts === 0
                                  ) {
                                    newSelectedTeams[currTeamIndex].qty = parseInt(
                                      e.target.value
                                    );
                                  } else if (
                                    currTeamIndex > -1 ||
                                    e.target.value % donationPerPts > 0
                                  ) {
                                    newSelectedTeams.splice(currTeamIndex, 1);
                                    alert(t(
                                      "Please input your donation in multiple of IDR ") +
                                      formatMoney(donationPerPts, 0, "", "", "ID")
                                    )
                                  }

                                  setSelectedTeams(newSelectedTeams);
                                }}
                              />
                            </div>
                            <a
                              className="flex justify-center items-center"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                let newSelectedTeams = selectedTeams.slice();
                                if (currTeamIndex > -1) {
                                  newSelectedTeams[currTeamIndex].qty =
                                    newSelectedTeams[currTeamIndex].qty + donationPerPts;
                                } else {
                                  newSelectedTeams.push({
                                    id: team.id,
                                    team: team.id,
                                    qty: donationPerPts,
                                    // pricePerItem: donationPerPts,
                                    pricePerItem: 1,
                                    teamName: team.name,
                                  });
                                }
                                setSelectedTeams(newSelectedTeams);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="text-theme-button"
                              />
                            </a>
                          </div>
                        </div> : null}
                    </div>
                  </div>
                  <div className="text-xl mb-2 text-center font-heading">
                    {name}
                  </div>
                  <div className="flex w-full">
                    <button
                      onClick={() => {
                        dispatch(fetchGroupMembers(team.id, challengeId));
                        showInfoModal(team);
                      }}
                      className={`w-full text-white bg-theme-button hover:bg-theme-buttonHover py-2 text-sm font-semibold`}
                    >
                      {t("Team Profile")}
                    </button>
                    <button
                      onClick={() => {
                        dispatch(fetchGroupDonations(team.id, donationProgramId));
                        showDonorModal(team);
                      }}
                      className={`w-full text-white bg-theme-buttonSupp hover:bg-theme-buttonSuppHover py-2 text-sm font-semibold`}
                    >
                      {t("List Donors")}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex items-center">
            <div className="flex">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-yellow-300 mr-3 text-xl"
              />
            </div>
            <div>
              <p>{t("Donation Terms and Conditions 1")}</p>
              <p>{t("Donation Terms and Conditions 2")}</p>
            </div>
          </div>

          {/* Section footer */}
          <div
            className={`max-w-7xl w-full fixed bottom-0 z-10 transition duration-500 ease-in-out bg-white dark:bg-gray-900 px-8 sm:px-12 py-2 ${selectedTeams.length > 0 ? "opacity-1" : "opacity-0"
              }`}
          >
            <div className="flex mb-4">
              <div className="flex items-center flex-grow text-sm font-semibold">
                <div className="flex-grow"></div>

                <div className="text-right font-bold text-2xl flex items-center justify-end">
                  <div className="text-sm mr-2">IDR</div>
                  {formatMoney(
                    totalQty,
                    0,
                    "",
                    "",
                    i18n.language
                  )}
                </div>
              </div>

              <button
                onClick={() => setIsModalOpen(true)}
                disabled={selectedTeams.length < 1}
                className={`btn ${selectedTeams.length > 0
                  ? "bg-theme-button hover:bg-theme-buttonHover"
                  : "bg-gray-200"
                  } flex justify-center text-white ml-4`}
              >
                <span className="text-sm mr-2 font-bold">{t("Continue")}</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamList;
