import React from "react";

function Detail({ t }) {
  return (
    <section className="relative">
      {/* Background image */}
      {/* <div className="absolute inset-0 h-128 pt-16 box-content -z-1">
        <div
          className="absolute inset-0 bg-gradient-to-t from-white"
          aria-hidden="true"
        ></div>
      </div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        {/* <div className="pt-32 pb-12 md:pt-40 md:pb-16"> */}
        <div>
          {/* Featured article */}
          <div className="max-w-6xl">
            <article data-aos="fade-up" data-aos-delay="200">
              <div className="mb-4">
                {/* Title and excerpt */}
                <div className="">
                  <p className="mb-4">{t("Programme Text 1")}</p>
                  <p className="mb-4">{t("Programme Text 2")}</p>
                </div>
              </div>
            </article>

            <div className="md:grid md:grid-cols-2 md:gap-8">
              <article data-aos="fade-up" data-aos-delay="200">
                <div className="mb-12">
                  {/* Title and excerpt */}
                  <div className="">
                    {/* <Link to="/blog-post"> */}
                    <h1 className="text-2xl mb-2 bg-theme-buttonSupp text-center text-white py-4">{t("Objectives")}</h1>
                    {/* </Link> */}
                    <ul className="list-disc ml-8">
                      <li>The race is being undertaken to raise funds that will be channelled through Yayasan Benih Baik as donations. </li>
                      <li>Everyone (including external parties) can donate to support this initiative.</li>
                    </ul>
                  </div>
                </div>
              </article>

              <article data-aos="fade-up" data-aos-delay="200">
                <div className="mb-12">
                  {/* Title and excerpt */}
                  <div className="">
                    {/* <Link to="/blog-post"> */}
                    <h1 className="text-2xl mb-2 bg-theme-buttonSupp text-center text-white py-4">{t("Point Conversion")}</h1>
                    {/* </Link> */}
                    <ul className="list-disc ml-8">
                      <li>Every 1 km of running is equal to 1 activity point.</li>
                      <li>Every 3 km of cycling is equal to 1 activity point.</li>
                    </ul>
                  </div>
                </div>
              </article>
            </div>

            <div className="md:grid md:grid-cols-2 md:gap-8">
              <article data-aos="fade-up" data-aos-delay="200">
                <div className="mb-12">
                  {/* Title and excerpt */}
                  <div className="">
                    {/* <Link to="/blog-post"> */}
                    <h1 className="text-2xl mb-2 bg-theme-buttonSupp text-center text-white py-4">{t("Donations")}</h1>
                    {/* </Link> */}
                    <ul className="list-disc ml-8">
                      <li>Donors will donate an amount of money in multiples of [IDR 50,000]; each multiple of [IDR 50,000] will be converted into 1 (one) activity point. </li>
                      <li>When making a donation, donors can select which group they would like to support. </li>
                      <li>Later, the amount of point(s) collected from each group will be converted into a target of km to achieve.</li>
                    </ul>
                  </div>
                </div>
              </article>

              <article data-aos="fade-up" data-aos-delay="200">
                <div className="mb-12">
                  {/* Title and excerpt */}
                  <div className="">
                    {/* <Link to="/blog-post"> */}
                    <h1 className="text-2xl mb-2 bg-theme-buttonSupp text-center text-white py-4">{t("Race Participants")}</h1>
                    {/* </Link> */}
                    <ul className="list-disc ml-8">
                      <li>All participants must register to join the race. </li>
                      <li>After registration, participants will be separated into groups and each group will be given a challenge and a target to be achieved.</li>
                      <li>The target (in km) is accumulated based on the number of donations collected.</li>
                      <li>We aim to have 555 participants (staff and alumni) to join in the race.</li>
                    </ul>
                  </div>
                </div>
              </article>
            </div>


          </div>
        </div>
      </div>
    </section>
  );
}

export default Detail;
