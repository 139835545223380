import React from "react";

function HeroAbout({ t }) {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="absolute inset-0 h-128 pt-16 box-content -z-1">
        <img className="absolute inset-0 w-full h-full object-cover opacity-25" src={require('../images/pwc-web-banner.jpg')} width="960" height="480" alt="About" />
        <div className="absolute inset-0 bg-gradient-to-t from-white dark:from-white" aria-hidden="true"></div>
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="text-center">
            <div className="relative flex justify-center items-center pt-8">
              <div className="absolute" data-aos="fade-down">
                <h1 className="h1 mb-4 lg:text-6xl">
                  {t("About")}{" "}
                  <span className="text-theme-button">{t("us")}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroAbout;
