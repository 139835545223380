import React from 'react';

import Header from '../partials/Header';
import TeamList from '../partials/TeamList';
import Footer from '../partials/Footer';

function Donation({t}) {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header t={t} />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <TeamList t={t} />

      </main>

      {/*  Site footer */}
      <Footer t={t}/>

    </div>
  );
}

export default Donation;