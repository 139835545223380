import React from "react";

function Detail({ t }) {
  return (
    <section className="relative">
      {/* Background image */}
      {/* <div className="absolute inset-0 h-128 pt-16 box-content -z-1">
        <div
          className="absolute inset-0 bg-gradient-to-t from-white"
          aria-hidden="true"
        ></div>
      </div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-16">
          {/* Featured article */}
          <div className="max-w-6xl">
            <article data-aos="fade-up" data-aos-delay="200">
              <div className="mb-4">
                {/* Title and excerpt */}
                <div className="md:flex md:items-center">
                  <img src={require("../images/beneficiary-pwc.png")} className="md:mr-6 mb-4 md:mb-0 mx-auto" />
                  <div>
                    <p className="mb-4">{t("Beneficiary Text 1")}</p>
                    <p className="mb-4">{t("Beneficiary Text 2")}</p>
                    <p className="mb-4">{t("Beneficiary Text 3")}</p>
                    <p className="mb-4">{t("Beneficiary Text 4")}</p>
                    <p className="mb-4">{t("Beneficiary Text 5")}</p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Detail;
