import {
  FETCH_LEADERBOARD_COMMENCED,
  FETCH_LEADERBOARD_SUCCESS,
  FETCH_LEADERBOARD_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  loadingLeaderboard: false,
  leaderboardList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboardList: action.payload,
        error: "",
        loadingLeaderboard: false,
      };
    case FETCH_LEADERBOARD_FAILURE:
      return {
        ...state,
        error: "Could not connect to the server. Please check your connection.",
        loadingLeaderboard: false,
      };
    case FETCH_LEADERBOARD_COMMENCED:
      return {
        ...state,
        error: "",
        loadingLeaderboard: true,
      };
    default:
      return state;
  }
};
