import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowUp,
  faTimes,
  faShoppingCart,
  faMinusCircle,
  faPlusCircle,
  faSave,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode.react";
import getYouTubeID from "get-youtube-id";
import FlipCountdown from "@rumess/react-flip-countdown";

// Import Swiper
import Swiper, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";

// components
import Modal from "../utils/Modal";
import { formatMoney } from "../helpers";
import i18n from "../i18n";
import moment from "moment";

// actions
import {
  fetchChallengeGroupInformation,
  fetchGroupMembers,
  fetchGroupDonations,
} from "../actions";

Swiper.use([Autoplay, Navigation]);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function PerformerList({ t }) {
  // constants declaration
  const { height, width } = useWindowDimensions();
  const donationPerPts = 50000;
  const timestampDonationOpen = 1616173200;

  // history instantiation
  let history = useHistory();

  // Hooks instantiation
  const dispatch = useDispatch();

  // State instantiation
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [isModalDonorOpen, setIsModalDonorOpen] = useState(false);
  const [isModalManualOpen, setIsModalManualOpen] = useState(false);
  const [foundIndex, setFoundIndex] = useState(-1);
  const [tempItem, setTempItem] = useState(null);
  const [showIndex, setShowIndex] = useState(0);
  const [carousel, setCarousel] = useState(null);
  const [totalDonationPts, setTotalDonationPts] = useState(0);
  const [totalActivityPts, setTotalActivityPts] = useState(0);

  // Props from reducers
  const runners = useSelector((state) => state.challenge.groupInfo);
  const groupMembers = useSelector((state) => state.challenge.groupMembers);
  const loadingGroupMembers = useSelector(
    (state) => state.challenge.loadingGroupMembers
  );
  const groupDonations = useSelector((state) => state.challenge.groupDonations);
  const donationProgramId = useSelector((state) => state.config.donationId);

  // component did mount
  useEffect(() => {
    // code to run on component mount
    dispatch(fetchChallengeGroupInformation(donationProgramId));
    if(moment().unix() >= timestampDonationOpen) {
      history.push("/donate")
    }
  }, []);

  useEffect(() => {
    if (runners && runners.length > 0) {
      var totalAllDonation = 0,
        totalBurnedPackage = 0;

      runners.map((team) => {
        if (team.pointDonated) {
          var donatedPoint = parseInt(team.pointDonated);
          var burnedPoint = parseInt(team.pointBurned);

          totalAllDonation += team.pointDonated ? donatedPoint : 0;
          totalBurnedPackage += team.pointBurned ? burnedPoint : 0;
        }
      });

      setTotalDonationPts(totalAllDonation);
      setTotalActivityPts(totalBurnedPackage);
    }
  }, [runners]);

  useEffect(() => {
    let newTotalQty = 0;

    selectedTeams.map((team) => {
      newTotalQty += parseInt(team.qty);
    });
    setTotalQty(newTotalQty);
  }, [selectedTeams]); // triggered on route change

  useEffect(() => {
    if (selectedTeam) {
      let newFoundIndex = selectedTeams.findIndex(
        (x) => x.id === selectedTeam.id
      );

      setFoundIndex(newFoundIndex);
    }
  }, [selectedTeam, selectedTeams]);
  //up till here

  // scrolling to top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const showInfoModal = (team) => {
    setSelectedTeam(team);
    setIsModalInfoOpen(true);
  };

  const hideInfoModal = () => {
    setSelectedTeam(null);
    setShowIndex(0);
    setCarousel(null);
    setIsModalInfoOpen(false);
  };

  const showDonorModal = (team) => {
    setSelectedTeam(team);
    // if (selectedTeams.findIndex((x) => x.id === team.id) > -1) {
    //   setTempItem(
    //     selectedTeams[selectedTeams.findIndex((x) => x.id === team.id)]
    //   );
    // } else {
    //   setTempItem({ id: team.id, qty: "", comment: "", songPicked: "" });
    // }
    setIsModalDonorOpen(true);
  };

  const hideDonorModal = (flag) => {
    setSelectedTeam(null);
    // setTempItem(null);
    setIsModalDonorOpen(false);
  };

  const showManualModal = (team) => {
    setSelectedTeam(team);
    setIsModalManualOpen(true);
  };

  const hideManualModal = (team) => {
    setSelectedTeam(team);
    setIsModalManualOpen(false);
  };

  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-col">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div
            className="max-w-3xl mx-auto text-center pb-12 md:pb-20"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            {/* <h1 className="h1 mb-4 pt-12">{t("Support Bands")}</h1> */}
            <h1 className="h1 mb-4 lg:text-6xl">{t("Performers List")}</h1>
            {/* <p className="text-xl text-gray-600 dark:text-gray-400">
              {t("Let’s give back to our teacher through")}
            </p> */}
          </div>

          <div
            className="mb-20 text-center"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <h4 className="h4">{t("Donation Open in")}</h4>
            <FlipCountdown
              hideYear
              hideMonth
              dayTitle={t("Days")}
              hourTitle={t("Hours")}
              minuteTitle={t("Minutes")}
              secondTitle={t("Seconds")}
              endAt={moment
                .unix(timestampDonationOpen)
                .format("YYYY-MM-DD HH:mm:ss")} // Date/Time
            />
          </div>

          <div
            className="w-full bg-white dark:bg-gray-900"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="w-full grid grid-cols-2 mb-2">
              <div className="flex items-center font-semibold">
                {t("Total Donation Collected")}
              </div>
              <div className="text-right font-bold text-xl flex items-center justify-end hidden md:flex">
                <div className="text-sm mr-2">IDR</div>
                {formatMoney(
                  totalDonationPts * donationPerPts,
                  0,
                  "",
                  "",
                  i18n.language
                )}
              </div>
              <div className="text-right font-bold text-xl flex items-center justify-end md:hidden">
                <div className="text-sm mr-2">IDR</div>
                {formatMoney(
                  (totalDonationPts * donationPerPts) / 1000,
                  0,
                  "",
                  "",
                  i18n.language
                )}
                <div className="text-sm">k</div>
              </div>
            </div>

            <div className="w-full flex items-center mb-4">
              <input
                type="text"
                value={searchTerm}
                className="form-input flex-grow text-sm"
                placeholder={t("Search your band by name")}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <Modal
            id="modal"
            ariaLabel="modal-gallery-process"
            show={isModalDonorOpen}
            handleClose={hideDonorModal}
            customHeight={"h-3/4"}
            customWidth={"w-3/4 md:w-1/2"}
          >
            <div className="relative pb-10 px-3 flex flex-col bg-white dark:bg-gray-800 text-black dark:text-white">
              <div className="sticky pt-4 top-0 pb-2 text-2xl w-full flex px-3 bg-white dark:bg-gray-800">
                <p className="w-full font-bold text-xl text-gray-600 dark:text-gray-400">
                  {selectedTeam && selectedTeam.name} (
                  {groupDonations && groupDonations.length} {t("donors")})
                </p>
                <button onClick={hideDonorModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              <div className="px-3">
                {groupDonations &&
                  groupDonations.map((donor) => {
                    return (
                      <div className="my-4">
                        <p className="font-semibold text-lg">{donor.name}</p>
                        <div>
                          {donor.package} {t("points")}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Modal>

          {/* Modal */}
          <Modal
            id="modal"
            ariaLabel="modal-gallery-process"
            show={isModalInfoOpen}
            handleClose={hideInfoModal}
            customHeight={"h-9/16"}
            customWidth={"w-3/4 md:w-1/2"}
          >
            <div className="relative px-3 pb-10 flex flex-col bg-white dark:bg-gray-800 text-black dark:text-white">
              <div className="sticky pt-4 top-0 pb-2 text-2xl w-full flex px-3 bg-white dark:bg-gray-800">
                <p className="w-full font-bold text-xl text-gray-600 dark:text-gray-300 tracking-wider">
                  {selectedTeam && selectedTeam.name}
                </p>
                <button onClick={hideInfoModal}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              <div className="w-full">
                <div className="sticky pt-4 top-12 pb-3 tracking-wider font-bold text-sm uppercase mb-2 bg-white dark:bg-gray-800 px-3">
                  {t("Profile")}
                </div>

                <div className="rounded overflow-hidden mx-auto w-full md:w-1/4 mb-4">
                  <img
                    src={
                      groupMembers && groupMembers.length > 0
                        ? groupMembers[0].imageUrl
                        : require("../logo/avatar-placeholder.png")
                    }
                  />
                </div>

                <div className="text-sm px-3">
                  {groupMembers &&
                    groupMembers.length > 0 &&
                    groupMembers[0].remarks}
                </div>
              </div>
            </div>
          </Modal>

          {/* Teams card grid */}
          <div
            className="flex-grow grid gap-3 grid-cols-1 md:grid-cols-3 pb-20 w-full"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            {(searchTerm
              ? runners.filter((x) =>
                  x.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
              : runners
            ).map((team, index) => {
              const { id, logoUrl, name, pointBurned, pointDonated } = team;
              let currTeamIndex = selectedTeams.findIndex((x) => x.id === id);
              let qty = 0;
              if (currTeamIndex > -1) qty = selectedTeams[currTeamIndex].qty;

              return (
                <div
                  key={id.toString()}
                  className={`rounded-lg overflow-hidden dark:bg-gray-700 shadow flex flex-col items-center`}
                >
                  <div className="flex-1 items-center grid grid-cols-2">
                    <div className="rounded overflow-hidden m-4">
                      <img src={logoUrl} />
                    </div>
                    <div className="flex flex-col justify-center p-3">
                      <div className="grid grid-cols-5">
                        <div className="text-xs col-span-4 flex items-center">
                          {t("Activity Pts.")}
                        </div>
                        <div className="text-right font-bold text-lg">
                          {pointBurned}
                        </div>
                      </div>
                      <div className="grid grid-cols-5">
                        <div className="text-xs col-span-4 flex items-center">
                          {t("Donation Pts.")}
                        </div>
                        <div className="text-right font-bold text-lg">
                          {pointDonated}
                        </div>
                      </div>
                      {/* <div className="text-xs col-span-4 my-2">
                        {t("Donation Package")}
                      </div>
                      <div className="grid grid-cols-5">
                        <a
                          className="flex justify-center items-center"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            let newSelectedTeams = [...selectedTeams];
                            let teamIndex = newSelectedTeams.findIndex(
                              (x) => x.id === id
                            );
                            if (teamIndex > -1) {
                              if (newSelectedTeams[teamIndex].qty === 1)
                                newSelectedTeams.splice(teamIndex, 1);
                              else {
                                newSelectedTeams[teamIndex].qty =
                                  newSelectedTeams[teamIndex].qty - 1;
                              }

                              setSelectedTeams(newSelectedTeams);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            className="text-theme-button"
                          />
                        </a>
                        <div className="col-span-3">
                          <input
                            type="text"
                            className="rounded border-1 w-full border-gray-200 dark:border-gray-600 hover:border-theme-button text-center text-xl font-bold dark:bg-gray-700"
                            value={qty}
                            onChange={(e) => {
                              let newSelectedTeams = [...selectedTeams];
                              let teamIndex = newSelectedTeams.findIndex(
                                (x) => x.id === id
                              );
                              if (
                                teamIndex > -1 &&
                                parseInt(e.target.value) > 1
                              ) {
                                newSelectedTeams[teamIndex].qty = parseInt(
                                  e.target.value
                                );
                              } else if (
                                teamIndex > -1 ||
                                isNaN(parseInt(e.target.value))
                              ) {
                                newSelectedTeams.splice(teamIndex, 1);
                              } else {
                                newSelectedTeams.push({
                                  ...team,
                                  qty: parseInt(e.target.value),
                                });
                              }
                              setSelectedTeams(newSelectedTeams);
                            }}
                          />
                        </div>
                        <a
                          className="flex justify-center items-center"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            let newSelectedTeams = [...selectedTeams];
                            let teamIndex = newSelectedTeams.findIndex(
                              (x) => x.id === id
                            );
                            if (teamIndex > -1) {
                              newSelectedTeams[teamIndex].qty =
                                newSelectedTeams[teamIndex].qty + 1;
                            } else {
                              newSelectedTeams.push({ ...team, qty: 1 });
                            }
                            setSelectedTeams(newSelectedTeams);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="text-theme-button"
                          />
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className="text-xl font-bold mb-2 text-center">
                    {name}
                  </div>
                  <div className="flex w-full">
                    {/* <button
                      onClick={() => {
                        dispatch(fetchGroupMembers(team.id));
                        showInfoModal(team);
                      }}
                      className={`w-full text-white bg-theme-buttonSupp hover:bg-theme-buttonSuppHover py-2 text-sm font-semibold`}
                    >
                      {t("Runner Profile")}
                    </button> */}
                    <button
                      onClick={() => {
                        dispatch(fetchGroupDonations(team.id));
                        showDonorModal(team);
                      }}
                      className={`w-full text-white bg-theme-button hover:bg-theme-buttonHover py-2 text-sm font-semibold`}
                    >
                      {t("List Donors")}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PerformerList;
