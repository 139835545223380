import axios from "axios";
import qs from "qs";
import {
  FETCH_CHALLENGE_GROUP_COMMENCED,
  FETCH_CHALLENGE_GROUP_SUCCESS,
  FETCH_CHALLENGE_GROUP_FAILURE,
  FETCH_DONATION_ADDONS_COMMENCED,
  FETCH_DONATION_ADDONS_SUCCESS,
  FETCH_DONATION_ADDONS_FAILURE,
  FETCH_GROUP_MEMBERS_COMMENCED,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_MEMBERS_FAILURE,
  FETCH_GROUP_DONATIONS_COMMENCED,
  FETCH_GROUP_DONATIONS_SUCCESS,
  FETCH_GROUP_DONATIONS_FAILURE,
  FETCH_CHALLENGE_PARTNER_SUCCESS,
  FETCH_CHALLENGE_PARTNER_FAILURE,
  FETCH_CHALLENGE_PARTNER_COMMENCED,
  ENROL_CHALLENGE_SUCCESS,
  ENROL_CHALLENGE_FAILURE,
  ENROL_CHALLENGE_COMMENCED,
  RESET_REGISTRANT_INFO,
} from "./types";

export const fetchChallengeGroupInformation = (donationProgramId) => {
  let url = `https://aktivin.et.r.appspot.com/donation/groups/${donationProgramId}`;
  
  return (dispatch) => {
    dispatch({ type: FETCH_CHALLENGE_GROUP_COMMENCED });
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((success) => {
        fetchChallengeGroupInformationSuccess(dispatch, success.data);
      })
      .catch(() => fetchChallengeGroupInformationFailed(dispatch));
  };
};

const fetchChallengeGroupInformationSuccess = (dispatch, message) => {
  dispatch({ type: FETCH_CHALLENGE_GROUP_SUCCESS, payload: message });
};

const fetchChallengeGroupInformationFailed = (dispatch) => {
  dispatch({ type: FETCH_CHALLENGE_GROUP_FAILURE });
};

export const fetchDonationAddOns = (donationProgramId) => {
  //let proxyurl = "https://frozen-garden-73124.herokuapp.com/";
  let url = `https://api.aktivin.id/donationaction.php`;
  var myObject = {
    action: "fetch_donation_addons",
    donationProgramId,
  };

  return (dispatch) => {
    dispatch({ type: FETCH_DONATION_ADDONS_COMMENCED });
    axios
      //.post(proxyurl + url, qs.stringify(myObject), {
      .post(url, qs.stringify(myObject), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((success) => {
        fetchDonationAddOnsSuccess(dispatch, success.data);
      })
      .catch(() => fetchDonationAddOnsFailure(dispatch));
  };
};

const fetchDonationAddOnsSuccess = (dispatch, message) => {
  dispatch({ type: FETCH_DONATION_ADDONS_SUCCESS, payload: message });
};

const fetchDonationAddOnsFailure = (dispatch) => {
  dispatch({ type: FETCH_DONATION_ADDONS_FAILURE });
};

export const fetchGroupMembers = (groupId, challengeId) => {
  let url = `https://aktivin.et.r.appspot.com/donation/participant/${challengeId}/${groupId}`;
  
  return (dispatch) => {
    dispatch({ type: FETCH_GROUP_MEMBERS_COMMENCED });
    axios
      .get(url)
      .then((success) => {
        fetchGroupMembersSuccess(dispatch, success.data);
      })
      .catch(() => fetchGroupMembersFailed(dispatch));
  };
};

const fetchGroupMembersSuccess = (dispatch, message) => {
  dispatch({ type: FETCH_GROUP_MEMBERS_SUCCESS, payload: message });
};

const fetchGroupMembersFailed = (dispatch) => {
  dispatch({ type: FETCH_GROUP_MEMBERS_FAILURE });
};

export const fetchGroupDonations = (groupId, donationProgramId) => {
  let url = `https://aktivin.et.r.appspot.com/donation/donator/${donationProgramId}/${groupId}`;
  
  return (dispatch) => {
    dispatch({ type: FETCH_GROUP_DONATIONS_COMMENCED });
    axios
      .get(url)
      .then((success) => {
        fetchGroupDonationsSuccess(dispatch, success.data);
      })
      .catch(() => fetchGroupDonationsFailed(dispatch));
  };
};

const fetchGroupDonationsSuccess = (dispatch, message) => {
  dispatch({ type: FETCH_GROUP_DONATIONS_SUCCESS, payload: message });
};

const fetchGroupDonationsFailed = (dispatch) => {
  dispatch({ type: FETCH_GROUP_DONATIONS_FAILURE });
};

export const registerNewTeam = (
  name,
  email,
  contactNo,
  captainName,
  targetNumber,
  teamMember,
  challengeId,
  programName,
  target
) => {
  let url = "https://aktivin.et.r.appspot.com/custom/pledge-team/register";
  // let url = "localhost:8080/custom/hawkspledge/register";
  // let urlImageUpload = `https://api.aktivin.id/uploadphoto.php`;

  // let formData = new FormData();
  // formData.append("photo", profilePictureUrl.object);

  return (dispatch) => {
    dispatch({ type: ENROL_CHALLENGE_COMMENCED });
    // axios
    //   .post(urlImageUpload, formData, {
    //     headers: {
    //       "content-type": "multipart/form-data",
    //     },
    //   })
    //   .then((file) => {
    //     if (file.data.error) {
    //       enrolChallengeFailed(dispatch);
    //     } else {
    var myObject = {
      name,
      email,
      contactNo,
      remarks:
        "Captain Name: " +
        captainName +
        "; Captain Contact No.: " +
        contactNo +
        "; Target Sembako Pack: " +
        targetNumber,
      captainName,
      teamMember,
      challengeId,
      programName,
      target,
    };

    axios
      .post(url, myObject, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((success) => {
        enrolChallengeSuccess(dispatch, success.data);
      })
      .catch(() => enrolChallengeFailed(dispatch));
  };
  // });
  //   };
};

export const enrolTeamRegistration = (
  name,
  email,
  contact_no,
  group_id,
  merchandise1,
  merchandise2,
  merchandise3,
  merchandise4,
  address,
  localitiesId,
  postcode,
  programName,
  tos1,
  tos2,
  tos3,
  tos4
) => {
  let url = "https://aktivin.et.r.appspot.com/micro/register/member";

  return (dispatch) => {
    dispatch({ type: ENROL_CHALLENGE_COMMENCED });
    var myObject = {
      name,
      email,
      contact_no,
      group_id,
      merchandise1,
      merchandise2,
      merchandise3,
      merchandise4,
      address,
      localitiesId,
      postcode,
      programName,
      tos1,
      tos2,
      tos3,
      tos4
    };

    axios
      .post(url, myObject, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((success) => {
        enrolChallengeSuccess(dispatch, success.data);
      })
      .catch(() => enrolChallengeFailed(dispatch));
  };
};

const enrolChallengeSuccess = (dispatch, message) => {
  dispatch({ type: ENROL_CHALLENGE_SUCCESS, payload: message });
};

const enrolChallengeFailed = (dispatch) => {
  dispatch({ type: ENROL_CHALLENGE_FAILURE });
};

export const resetRegistrantInfo = () => {
  return (dispatch) => {
    dispatch({ type: RESET_REGISTRANT_INFO });
  };
};
