import React, { useEffect } from "react";
// Import Swiper
import Swiper, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Autoplay, Navigation]);

function Carousel({ t }) {
  const sponsors = [
    {
      name: "Medco Foundation",
      imgUrl: require("../logo/MF-Logo.png"),
      darkImgUrl: require("../logo/MF 2-Logo.png"),
    },
    {
      name: "Mahorahora",
      imgUrl: require("../logo/MH-Logo.png"),
      darkImgUrl: require("../logo/MH-Logo.png"),
    },
    {
      name: "Medco Foundation",
      imgUrl: require("../logo/MF-Logo.png"),
      darkImgUrl: require("../logo/MF 2-Logo.png"),
    },
    {
      name: "Mahorahora",
      imgUrl: require("../logo/MH-Logo.png"),
      darkImgUrl: require("../logo/MH-Logo.png"),
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const carousel = new Swiper(".carousel", {
      slidesPerView: "auto",
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      initialSlide: 0,
      spaceBetween: 24,
      autoplay: {
        delay: 1500,
      },
      navigation: {
        nextEl: ".carousel-next",
        prevEl: ".carousel-prev",
      },
    });
  }, []);

  return (
    <section className="border-t border-transparent md:py-12">
      <div className="py-12 md:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-16 md:pb-24">
            <h1 className="h2 mb-4">{t("Sponsored by")}</h1>
            {/* <p className="text-xl text-gray-600 dark:text-gray-400">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.</p> */}
          </div>
        </div>

        {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
        {/* * Custom styles in src/css/additional-styles/theme.scss */}
        <div className="carousel swiper-container">
          <div className="swiper-wrapper">
            {/* Carousel items */}
            {sponsors.map((sponsor, index) => {
              const { name, imgUrl, darkImgUrl } = sponsor;
              return (
                <div className="rounded swiper-slide max-w-sm dark:bg-gray-800 bg-white py-4">
                  <img
                    className="transition-opacity duration-300 mx-auto dark:hidden"
                    src={imgUrl}
                    width="200"
                    height="200"
                    alt={"Carousel item " + index}
                  />
                  <img
                    className="transition-opacity duration-300 mx-auto hidden dark:block"
                    src={darkImgUrl}
                    width="200"
                    height="200"
                    alt={"Carousel item " + index}
                  />
                  <div className="flex flex-grow mt-3">
                    <a
                      className="inline-flex btn-sm mx-auto tracking-wider font-semibold"
                      href="#0"
                    >
                      {name}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Arrows */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="flex items-center justify-between mt-12 md:mt-16">
            <button className="rounded carousel-prev relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-theme-button hover:bg-theme-buttonHover dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out">
              <span className="sr-only">{t("Previous")}</span>
              <svg
                className="w-4 h-4 fill-current text-white transition duration-150 ease-in-out"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
              </svg>
            </button>
            <button className="rounded carousel-next relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-theme-button hover:bg-theme-buttonHover dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out">
              <span className="sr-only">{t("Next")}</span>
              <svg
                className="w-4 h-4 fill-current text-white transition duration-150 ease-in-out"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Carousel;
