import {
  PROCESS_DONATION_COMMENCED,
  PROCESS_DONATION_FAILURE,
  PROCESS_DONATION_SUCCESS,
  FETCH_DONATION_GRAPH_COMMENCED,
  FETCH_DONATION_GRAPH_SUCCESS,
  FETCH_DONATION_GRAPH_FAILURE,
  FETCH_MILEAGE_TOTAL_COMMENCED,
  FETCH_MILEAGE_TOTAL_SUCCESS,
  FETCH_MILEAGE_TOTAL_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  loadingDonation: false,
  donationResponse: null,
  graphData: [],
  loadingGraphData: false,
  mileageTotal: [],
  loadingMileageTotal: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROCESS_DONATION_COMMENCED:
      return {
        ...state,
        loadingDonation: true,
        donationResponse: null,
      };
    case PROCESS_DONATION_SUCCESS:
      return {
        ...state,
        loadingDonation: false,
        donationResponse: action.payload,
      };
    case PROCESS_DONATION_FAILURE:
      return {
        ...state,
        loadingDonation: false,
        donationResponse: null,
      };
    case FETCH_DONATION_GRAPH_COMMENCED:
      return {
        ...state,
        graphData: [],
        loadingGraphData: true,
      };
    case FETCH_DONATION_GRAPH_SUCCESS:
      return {
        ...state,
        graphData: action.payload,
        loadingGraphData: false,
      };
    case FETCH_DONATION_GRAPH_FAILURE:
      return {
        ...state,
        graphData: [],
        loadingGraphData: false,
      };

    case FETCH_MILEAGE_TOTAL_COMMENCED:
      return {
        ...state,
        mileageTotal: [],
        loadingMileageTotal: true,
      };
    case FETCH_MILEAGE_TOTAL_SUCCESS:
      return {
        ...state,
        mileageTotal: action.payload,
        loadingMileageTotal: false,
      };
    case FETCH_MILEAGE_TOTAL_FAILURE:
      return {
        ...state,
        mileageTotal: [],
        loadingMileageTotal: false,
      };

    default:
      return state;
  }
};
