import React from "react";

import Header from "../partials/Header";
import HeroAbout from "../partials/HeroAbout";
// import FeaturesGallery from '../partials/FeaturesGallery';
// import Timeline from '../partials/Timeline';
// import Career from '../partials/Career';
// import FeaturesAnimation from '../partials/FeaturesAnimation';
// import Team from '../partials/Team';
// import CtaContact from '../partials/CtaContact';
import About1 from "../partials/About-1";
import About2 from "../partials/About-2";
import AboutSponsors from "../partials/About-Sponsors";
import Footer from "../partials/Footer";

function About({ t }) {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header t={t} />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroAbout t={t} />
        {/* <FeaturesGallery />
        <Timeline />
        <Career />
        <FeaturesAnimation />
        <Team />
        <CtaContact /> */}
        <About1 t={t} />
        <About2 t={t} />
        <AboutSponsors t={t} />
      </main>

      {/*  Site footer */}
      <Footer t={t}/>
    </div>
  );
}

export default About;
